<template>
  <div>
    <v-list width="100%" color="transparent">
      <v-list-item v-if="alerts.length === 0" class="px-3 pb-1">
        <div style="width: 100%" class="text-center caption white--text">
          気象警報・注意報 発表なし
        </div>
      </v-list-item>
      <v-list-item v-for="(item, i) in alerts" :key="i" class="px-2 pb-1">
        <v-card
          tile
          class="alert-card"
          @click="showAlert(item)"
          color="rgba(255,255,255,0.1)"
        >
          <v-card-text class="pa-2 white--text">
            <v-chip x-small dark :color="item.color" v-if="item.heading">
              {{ item.heading }}
            </v-chip>
            <div class="mt-1 body-2">
              {{ item.title }}
            </div>
            <div v-if="isAreaVisible" class="alert-area overline">
              {{ item.area }}
            </div>
            <div class="text-right">
              <span class="caption font-weight-light">
                {{ item.date }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-list-item>
    </v-list>
    <v-dialog v-model="dialog" scrollable width="600">
      <v-card color="white">
        <v-card-title class="headline">{{ target.title }}</v-card-title>
        <v-card-text class="alert-content">{{ target.content }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="dialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
const ALERT_URL = "https://data.riskma.net/bosai/alert";
const ALERT_POINT = "JPFC";
const ALERT_CITIES = ["4142500"];
const ALERT_RIVERS = [];
export default {
  name: "AlertInformation",
  data() {
    return {
      alerts: [],
      target: {
        title: "",
        content: ""
      },
      dialog: false,
      isAreaVisible: ALERT_CITIES.length >= 2
    };
  },
  computed: {
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },
  async mounted() {
    await this.fetchAlerts();
  },
  watch: {
    async baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        await this.fetchAlerts();
      }
    }
  },
  methods: {
    async fetchAlerts() {
      const params = {
        point: ALERT_POINT,
        city: ALERT_CITIES.join(","),
        river: ALERT_RIVERS.join(","),
        date: this.baseDate.format("YYYY/MM/DD HH:mm")
      };
      const response = await axios
        .create({
          responseType: "json"
        })
        .get(ALERT_URL, { params });
      if (response === null) {
        this.alerts = [];
        return;
      }
      this.alerts = response.data.map(item => {
        item.date = moment
          .utc(item.date, "YYYY/MM/DD HH:mm")
          .local()
          .format("YYYY/MM/DD HH:mm");
        return item;
      });
    },
    showAlert(alert) {
      this.target.title = alert.title;
      let content = "";
      if (alert.text) {
        content = alert.text;
      }
      if (alert.comment) {
        if (content !== "") {
          content += "\n\n";
        }
        content += alert.comment;
      }
      this.target.content = content;
      this.dialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.alert-card {
  width: 100%;
  .alert-area {
    opacity: 0.8;
    line-height: 1rem;
    font-size: 0.625rem !important;
  }
}
.alert-content {
  white-space: pre-line;
}
</style>
