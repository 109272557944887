import Vue from "vue";
import VueRouter from "vue-router";
import isMobile from "ismobilejs";
import store from "@/store";
import * as cognito from "../utils/cognito";
import { isPrivateMode, isPublicMode } from "../utils/common";
import Login from "../views/Login";
import MobileDashboard from "../views/Mobile";
import Dashboard from "../views/Dashboard";
import MobileCameraMonitor from "../views/MobileCameraMonitor";
import MobileChat from "../views/MobileChat";
import Admin from "../views/Admin";

Vue.use(VueRouter);

const isPhone = isMobile().phone;

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      isPublicMode ? next("/") : next();
    }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/mobile",
    name: "Mobile",
    component: MobileDashboard
  },
  {
    path: "/mobile-camera-monitor",
    name: "MobileCameraMonitor",
    component: MobileCameraMonitor
  },
  {
    path: "/mobile-chat",
    name: "MobileChat",
    component: MobileChat,
    beforeEnter: (to, from, next) => {
      isPublicMode ? next("/mobile") : next();
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter: (to, from, next) => {
      isPublicMode ? next("/") : next();
    }
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  if (to.name === "login") {
    next();
    return;
  }
  if (isPhone === true && !to.fullPath.startsWith("/mobile")) {
    next("/mobile");
    return;
  }
  try {
    if (isPrivateMode) {
      const session = await cognito.isAuthenticated();
      const token = session.idToken.jwtToken;
      const user = Object.assign({}, session.idToken.payload);
      user.token = token;
      user.id = user["cognito:username"];
      if (user.name === undefined) {
        user.name = user.id;
      }
      console.log(user.id);
      store.commit("SET_COGNITO_USER_INFO", user);
    } else {
      cognito.signout();
    }
    store.commit("SET_IS_NAVIGATION", !isPhone);
    store.commit("SET_IS_RIGHT_NAV", !isPhone);
    next();
  } catch (error) {
    if (isPrivateMode) {
      store.commit("SET_COGNITO_USER_INFO", {});
      cognito.signout();
      next("/login");
    } else {
      next("/");
    }
  }
});

export default router;
