<!-- ログイン画面 -->
<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-10" max-width="400">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                placeholder="ユーザー名"
                prepend-icon="mdi-account"
                type="text"
                :rules="[rules.required]"
                v-model="username"
              ></v-text-field>

              <v-text-field
                id="password"
                placeholder="パスワード"
                prepend-icon="mdi-lock"
                type="password"
                :rules="[rules.required]"
                v-model="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-text v-if="errorMessage">
            <div class="error-message">{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="login">ログイン</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as cognito from "../utils/cognito";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      rules: {
        required: value => !!value || "必須項目です"
      }
    };
  },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    }
  },
  created() {
    cognito.signout();
  },
  methods: {
    async login() {
      this.errorMessage = "";

      if (!this.$refs.form.validate()) {
        this.errorMessage = "バリデーションエラー";
        return;
      }

      cognito.signin(
        this.username,
        this.password,
        () => {
          const url = this.$route.query.redirect;
          if (url) {
            this.$router.replace(url);
          } else {
            this.$router.replace("/");
          }
        },
        () => {
          this.errorMessage = "ユーザ名もしくパスワードが間違っています。";
        }
      );
    }
  }
};
</script>
<style scoped>
.error-message {
  color: red;
  text-align: center;
}
</style>
