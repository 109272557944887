<script>
import { Line } from "vue-chartjs";
import _ from "lodash";
import moment from "moment";
const COLOR = {
  LABEL: "#01163F",
  QIN_ALL: "#91C46C",
  QOUT_ALL: "RED"
};
const BORDER_WIDTH = 3;

export default {
  name: "DamChart",
  extends: Line,
  props: {
    selectedObservatory: {
      type: Object
    }
  },
  data() {
    return {
      levels: [],
      qinAlls: [],
      qoutAlls: [],
      times: [],
      reverse: false,
      normalMinLevel: -1,
      normalMaxLevel: -1,
      startOperLevel: -1,
      floodMaxLevel: -1,
      preliminaryReleaseLevel: -1,
      floodStorageLevel: -1,
      landform: [],
      datacollection: {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "貯水位",
            yAxisID: "level",
            xAxisID: "data",
            unit: "m",
            fill: false,
            borderWidth: BORDER_WIDTH,
            borderColor: COLOR.LABEL,
            backgroundColor: COLOR.LABEL,
            tooltipLabelColor: COLOR.LABEL,
            pointBackgroundColor: "white",
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "貯水位",
            yAxisID: "level",
            xAxisID: "data",
            unit: "m",
            fill: false,
            borderWidth: 3,
            borderDash: [10, 5],
            borderColor: "#3734dc",
            backgroundColor: "#3734dc",
            tooltipLabelColor: "#3734dc",
            pointBorderColor: "#3734dc",
            pointBackgroundColor: "white",
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "流入量",
            yAxisID: "qAll",
            xAxisID: "data",
            unit: "m3/s",
            fill: false,
            borderWidth: BORDER_WIDTH,
            borderColor: COLOR.QIN_ALL,
            backgroundColor: COLOR.QIN_ALL,
            tooltipLabelColor: COLOR.QIN_ALL,
            pointBackgroundColor: "white",
            pointRadius: 2,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "流入量",
            yAxisID: "qAll",
            xAxisID: "data",
            unit: "m3/s",
            fill: false,
            borderWidth: BORDER_WIDTH,
            borderDash: [10, 5],
            borderColor: COLOR.QIN_ALL,
            backgroundColor: COLOR.QIN_ALL,
            tooltipLabelColor: COLOR.QIN_ALL,
            pointBackgroundColor: "white",
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "放流量",
            yAxisID: "qAll",
            xAxisID: "data",
            unit: "m3/s",
            pointBackgroundColor: "white",
            fill: false,
            borderWidth: BORDER_WIDTH,
            borderColor: COLOR.QOUT_ALL,
            backgroundColor: COLOR.QOUT_ALL,
            tooltipLabelColor: COLOR.QOUT_ALL,
            pointRadius: 2,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "放流量",
            yAxisID: "qAll",
            xAxisID: "data",
            unit: "m3/s",
            pointBackgroundColor: "white",
            fill: false,
            borderWidth: BORDER_WIDTH,
            borderDash: [10, 5],
            borderColor: COLOR.QOUT_ALL,
            backgroundColor: COLOR.QOUT_ALL,
            tooltipLabelColor: COLOR.QOUT_ALL,
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          }
        ]
      },

      options: {
        layout: {
          padding: { right: 0, left: 0 }
        },
        scales: {
          yAxes: [
            {
              id: "level",
              position: "left",
              type: "linear",
              ticks: {
                reverse: this.reverse,
                max: 5,
                min: 0,
                stepSize: 1,
                beginAtZero: true,
                callback: label => {
                  return ("　　　　　  " + parseFloat(label).toFixed(1)).slice(
                    -5
                  );
                }
              },
              gridLines: {
                display: true
              }
            },
            {
              id: "qAll",
              position: "right",
              type: "linear",
              ticks: {
                reverse: this.reverse,
                min: 0,
                max: 30,
                stepSize: 0.5,
                beginAtZero: false
              },
              gridLines: {
                display: false,
                color: COLOR.QIN_ALL
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              position: "bottom",
              gridLines: {
                display: true
              },
              ticks: {
                fontColor: "#9a9a9a",
                display: true
              },
              categoryPercentage: 1.0,
              barPercentage: 1.0
            }
          ]
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            labelColor: function(tooltipItem, chart) {
              const dataset =
                chart.config.data.datasets[tooltipItem.datasetIndex];
              return {
                backgroundColor: dataset.tooltipLabelColor
              };
            },
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              return (
                label +
                " : " +
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] +
                " " +
                unit
              );
            }
          }
        },
        hover: {
          mode: "index",
          intersect: false
        },
        groundDataset: [],
        annotation: {
          events: ["click", "dblclick", "mouseover", "mouseout"],
          annotations: [
            {
              type: "box",
              drawTime: "beforeDatasetsDraw",
              yScaleID: "level",
              borderColor: "rgba(0,0,0,0)",
              yMin: 0,
              yMax: 0,
              backgroundColor: "#3D8AFF"
            },
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              borderColor: "#FF0000",
              borderWidth: 1.5,
              label: {
                content: "現在",
                position: "top",
                enabled: true
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    baseDate() {
      return this.$store.getters.baseDate;
    },
    mesuredData() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      }
      return this.selectedObservatory.timeSeries.filter(v => v.type === "real");
    },
    predictData() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      }
      return this.selectedObservatory.timeSeries.filter(
        v => v.type === "forecast"
      );
    }
  },
  mounted() {
    this.setLabelData();
    this.setData();
    this.setLandformData();
    this.setYAxesLimitData();
    this.setWaterBoxData();

    this.addPlugin({
      id: "horizontalLine",
      beforeDatasetsDraw: chart => {
        const gSet = chart.config.options.groundDataset;
        if (gSet.length === 0) return;
        const ctxPlugin = chart.chart.ctx;
        const xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
        const yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
        const minGround = _.minBy(gSet, o => {
          return o[0];
        });
        const maxGround = _.maxBy(gSet, o => {
          return o[0];
        });

        ctxPlugin.strokeStyle = "red";
        ctxPlugin.lineWidth = 2;
        ctxPlugin.beginPath();
        ctxPlugin.moveTo(xAxe.left, yAxe.bottom);
        for (let i = 0; i < gSet.length; i++) {
          let x = gSet[i][0] - minGround[0];
          x = (x / (maxGround[0] - minGround[0])) * xAxe.width + xAxe.left;

          let y = yAxe.max - yAxe.min - (gSet[i][1] - yAxe.min);
          y = (y / (yAxe.max - yAxe.min)) * yAxe.height + yAxe.top;
          ctxPlugin.lineTo(x, y);
        }
        ctxPlugin.lineTo(xAxe.right, yAxe.bottom);
        ctxPlugin.lineTo(xAxe.left, yAxe.bottom);
        ctxPlugin.closePath();
        ctxPlugin.fillStyle = "#f1eeea";
        ctxPlugin.fill();
      }
    });

    setTimeout(() => {
      this.renderChart(this.datacollection, this.options);
    }, 500);
  },
  watch: {
    selectedObservatory() {
      this.setLabelData();
      this.setData();
      this.setLandformData();
      this.setYAxesLimitData();
      this.setWaterBoxData();
      setTimeout(() => {
        this.renderChart(this.datacollection, this.options);
      }, 500);
    }
  },
  methods: {
    setLabelData() {
      this.times = this.mesuredData.map(r => {
        return moment
          .utc(r.date, "YYYY/MM/DD HH:mm")
          .local()
          .format("HH:mm");
      });
      for (let i = 0; i < this.predictData.length; i++) {
        const p = this.predictData[i];
        this.times.push(
          moment
            .utc(p.date, "YYYY/MM/DD HH:mm")
            .local()
            .format("HH:mm")
        );
      }
      this.datacollection.labels = this.times;
    },
    setData() {
      this.levels = this.mesuredData.map(r => {
        return r.level;
      });
      this.qinAlls = this.mesuredData.map(r => {
        return r.qinAll;
      });
      this.qoutAlls = this.mesuredData.map(r => {
        return r.qoutAll;
      });
      const predictLevels = this.predictData.map(r => {
        return r.level;
      });
      const predictQinAlls = this.predictData.map(r => {
        return r.qinAll;
      });
      const predictQoutAlls = this.predictData.map(r => {
        return r.qoutAll;
      });

      const emptys = Array(this.levels.length);
      emptys.fill(null);

      this.datacollection.datasets[0].data = this.levels;
      this.datacollection.datasets[1].data = emptys.concat(predictLevels);
      this.datacollection.datasets[2].data = this.qinAlls;
      this.datacollection.datasets[3].data = emptys.concat(predictQinAlls);
      this.datacollection.datasets[4].data = this.qoutAlls;
      this.datacollection.datasets[5].data = emptys.concat(predictQoutAlls);

      if (this.predictData.length > 0) {
        const mesuredLatestData = this.mesuredData[this.mesuredData.length - 1];
        const currentTime = moment
          .utc(mesuredLatestData.date, "YYYY/MM/DD HH:mm")
          .local();
        const remainder = currentTime.minutes() % 10;
        this.options.annotation.annotations[1].value = currentTime
          .subtract(remainder, "minutes")
          .format("HH:mm");
      }
    },

    setWaterBoxData() {
      const baseDateIndex = _.findIndex(this.times, o => {
        return this.baseDate.isSame(moment.utc(o, "YYYY/MM/DD HH:mm"));
      });

      const pastData = this.levels.slice(0, baseDateIndex - 2);

      const blueAreaY = _.findLast(pastData, level => {
        return level !== null;
      });

      const blueAreaYMax = blueAreaY
        ? blueAreaY
        : this.options.scales.yAxes[0].ticks.min;
      this.options.annotation.annotations[0].yMax = blueAreaYMax;
      this.options.annotation.annotations[0].yMin = this.options.scales.yAxes[0].ticks.min;
    },

    setYAxesLimitData() {
      const levelMin = parseFloat(_.min(this.levels));
      const levelMax = parseFloat(_.max(this.levels));
      if (this.landform.length > 0) {
        const landformYMin = _.minBy(this.landform, o => {
          return o[1];
        });
        const landformYMax = _.maxBy(this.landform, o => {
          return o[1];
        });

        if (landformYMin) {
          const min = landformYMin[1] < levelMin ? landformYMin[1] : levelMin;
          this.options.scales.yAxes[0].ticks.min = min - 0.5;
          this.options.annotation.annotations[1].yMin = min - 0.5;
        }

        if (landformYMax) {
          const max = landformYMax[1] > levelMax ? landformYMax[1] : levelMax;
          this.options.scales.yAxes[0].ticks.max = max + 1;
        }
      } else {
        this.options.scales.yAxes[0].ticks.min = levelMin - 1;
        this.options.scales.yAxes[0].ticks.max = levelMax + 5;
      }

      const qMax = Number(
        _.maxBy(_.concat(this.qinAlls, this.qoutAlls), v => {
          return parseInt(v);
        })
      );
      if (qMax > this.options.scales.yAxes[1].ticks.max) {
        this.options.scales.yAxes[1].ticks.max = qMax + 1;
      }
    },

    setLandformData() {
      if (this.landform.length > 0) {
        this.options.groundDataset = this.landform;
      }
    }
  }
};
</script>
