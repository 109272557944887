const BRANCH_OFFICE = [
  {
    name: "白石町役場",
    postal: "849-1112",
    address: "佐賀県杵島郡白石町福田1247-1",
    lat: 33.18094,
    lng: 130.14344
  }
];

export { BRANCH_OFFICE };
