<script>
import { Bar } from "vue-chartjs";
import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";
Chart.plugins.register(ChartAnnotationsPlugin);
import moment from "moment";

export default {
  extends: Bar,
  name: "RainChart",
  props: {
    selectedObservatory: {
      type: Object
    },
    displayHours: Number,
    displayInterval: Number,
    currentDate: String
  },
  data() {
    return {
      dataCollection: {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "累加雨量",
            unit: "mm",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "totalRain",
            xAxisID: "data",
            borderWidth: 2,
            borderColor: "#91C46C",
            backgroundColor: "#91C46C",
            pointBorderColor: "#91C46C",
            pointRadius: 2,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: false
          },
          {
            type: "bar",
            label: "10分雨量",
            yAxisID: "rain",
            xAxisID: "data",
            unit: "mm",
            backgroundColor: "#1C344C",
            tooltipLabelColor: "#1C344C",
            borderColor: "#1C344C",
            pointBackgroundColor: "white",
            borderWidth: 0.5,
            data: []
          }
        ]
      },
      options: {
        layout: {
          padding: {
            right: 0
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              const val =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (val !== null) {
                return label + " : " + val + " " + unit;
              }
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "totalRain",
              position: "right",
              gridLines: {
                display: true
              },
              ticks: {
                max: 20,
                min: 0,
                stepSize: 20,
                callback: label => {
                  return parseFloat(label).toFixed(0);
                }
              }
            },
            {
              id: "rain",
              position: "left",
              gridLines: {
                display: false
              },
              ticks: {
                max: 20,
                min: 0,
                stepSize: 1,
                callback: label => {
                  return parseFloat(label).toFixed(1);
                }
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              type: "time",
              gridLines: {
                display: false
              },
              time: {
                unit: "hour",
                unitStepSize: 1,
                tooltipFormat: "MM/DD HH:mm",
                displayFormats: {
                  minute: "HH:mm",
                  hour: "HH:mm",
                  day: "HH:mm",
                  week: "HH:mm",
                  month: "HH:mm"
                }
              },
              ticks: {
                display: true,
                autoSkip: true
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "index",
          intersect: false
        },
        annotation: {
          events: ["click", "dblclick", "mouseover", "mouseout"],
          annotations: [
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              value: "02:00",
              borderColor: "#FF0000",
              borderWidth: 1.5,
              label: {
                content: "現在",
                position: "top",
                enabled: true
              }
            }
          ]
        },
        legend: {
          display: false
        }
      }
    };
  },
  computed: {
    baseDate() {
      return moment.utc(this.currentDate, "YYYY/MM/DD HH:mm");
    },
    minDate() {
      return this.baseDate
        .clone()
        .subtract(this.displayInterval === 10 ? 3 : 23, "hours");
    },
    maxDate() {
      return this.baseDate.clone().add(this.displayHours, "hours");
    },
    rainVolumes() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      }
      return this.selectedObservatory.timeSeries
        .filter(ts =>
          moment
            .utc(ts.date, "YYYY/MM/DD HH:mm")
            .isBetween(this.minDate, this.maxDate, null, "[]")
        )
        .filter(
          ts => this.displayInterval === 10 || ts.date.substr(-2) === "00"
        );
    }
  },
  watch: {
    selectedObservatory() {
      this.setTimeSeries();
      this.setYAxesLimitData();
      this.renderChart(this.dataCollection, this.options);
    },
    displayHours() {
      this.setTimeSeries();
      this.setYAxesLimitData();
      this.renderChart(this.dataCollection, this.options);
    },
    displayInterval() {
      this.setTimeSeries();
      this.setYAxesLimitData();
      this.renderChart(this.dataCollection, this.options);
    }
  },
  mounted() {
    this.setTimeSeries();
    this.setYAxesLimitData();
    setTimeout(() => {
      this.renderChart(this.dataCollection, this.options);
    }, 500);
  },
  methods: {
    setTimeSeries() {
      if (this.rainVolumes.length === 0) {
        this.dataCollection.labels = [];
        this.dataCollection.datasets[0] = [];
        this.dataCollection.datasets[1] = [];
        return;
      }

      // date range
      this.options.scales.xAxes[0].ticks.min = this.minDate.toDate();
      this.options.scales.xAxes[0].ticks.max = this.maxDate.toDate();

      // labels
      this.dataCollection.labels = this.rainVolumes.map(ts => {
        if (this.displayInterval === 60 && ts.date.substr(-2) !== "00") {
          return null;
        }
        return moment
          .utc(ts.date, "YYYY/MM/DD HH:mm")
          .local()
          .toDate();
      });

      // total
      this.dataCollection.datasets[0].data = this.rainVolumes.map(ts => {
        if (this.displayInterval === 60 && ts.date.substr(-2) !== "00") {
          return null;
        }
        return ts.total === undefined ? null : ts.total.toFixed(2);
      });

      // min10
      this.dataCollection.datasets[1].label = `${this.displayInterval}分雨量`;
      this.dataCollection.datasets[1].data = this.rainVolumes.map(ts => {
        if (this.displayInterval === 60 && ts.date.substr(-2) !== "00") {
          return null;
        }
        if (this.displayInterval === 60) {
          return ts.min60 === undefined ? null : ts.min60.toFixed(2);
        } else {
          return ts.min10 === undefined ? null : ts.min10.toFixed(2);
        }
      });
      // annotations
      this.options.annotation.annotations[0].value = this.baseDate
        .local()
        .toDate();
    },
    setYAxesLimitData() {
      const maxLevel = Math.max(
        ...this.rainVolumes.map(r => {
          if (this.displayInterval === 60) {
            return r.min60 !== undefined ? r.min60 : 0;
          } else {
            return r.min10 !== undefined ? r.min10 : 0;
          }
        })
      );
      const totalMaxLevel = Math.max(
        ...this.rainVolumes.map(r => (r.total !== undefined ? r.total : 0))
      );

      this.options.scales.yAxes[0].ticks.min = 0;
      this.options.scales.yAxes[0].ticks.max =
        totalMaxLevel < 100 ? 100 : Math.floor(totalMaxLevel + 20);

      this.options.scales.yAxes[1].ticks.min = 0;
      this.options.scales.yAxes[1].ticks.max =
        maxLevel < 5 ? 5 : Math.floor(maxLevel + 2);
    }
  }
};
</script>

<style scoped></style>
