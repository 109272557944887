<template>
  <div class="d-flex" style="height: 100%">
    <div style="width: 100%">
      <base-map />
    </div>
    <div v-if="isShowMapDetail" style="border-left: 1px solid #c1c1c1">
      <v-toolbar dense flat color="primary">
        <v-card class="body-2 white--text" color="primary" flat width="320">
          {{ mapDetail.title }}
        </v-card>
        <v-spacer />
        <v-btn class="mr-1" dark icon small @click="closeMapDetail">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card
        tile
        flat
        height="calc(100dvh - 60px)"
        width="400"
        style="overflow-y: auto;"
      >
        <template v-if="mapDetail.contents === 'stage-detail'">
          <StageDetail />
        </template>
        <template v-if="mapDetail.contents === 'rainfall-detail'">
          <RainDetail />
        </template>
        <template v-if="mapDetail.contents === 'dam-detail'">
          <DamDetail />
        </template>
        <template v-if="mapDetail.contents === 'camera-detail'">
          <CameraDetail />
        </template>
        <template v-if="mapDetail.contents === 'live-camera-detail'">
          <LiveCameraDetail />
        </template>
      </v-card>
    </div>
    <monitoring v-if="isMonitoring" />
    <CameraMonitor v-if="isCameraMonitor && !isShowMapDetail" />
    <RightSideContents v-if="isRightSideContents && !isShowMapDetail" />
  </div>
</template>

<script>
import { Risk } from "../enums/Risk";
import BaseMap from "../components/base-map/BaseMap";
import Monitoring from "../components/Monitoring";
import StageDetail from "../components/base-map/details/StageDetail";
import RainDetail from "../components/base-map/details/RainDetail";
import DamDetail from "../components/base-map/details/DamDetail";
import CameraDetail from "../components/base-map/details/CameraDetail";
import LiveCameraDetail from "../components/base-map/details/LiveCameraDetail";
import CameraMonitor from "../components/CameraMonitor";
import RightSideContents from "../components/RightSideContents";

export default {
  name: "Dashboard",
  components: {
    Monitoring,
    BaseMap,
    StageDetail,
    RainDetail,
    DamDetail,
    CameraDetail,
    LiveCameraDetail,
    CameraMonitor,
    RightSideContents
  },
  data() {
    return {
      Risk
    };
  },
  watch: {
    selectedRisk() {
      this.closeMapDetail();
    },
    baseDate() {
      if (this.isShowMapDetail !== true) {
        return;
      }
      const info = this.$store.getters.selectedObservatory;
      this.$store.dispatch("FETCH_SELECTED_OBSERVATORY_DATA", info);
    }
  },
  computed: {
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    isShowMapDetail() {
      return this.$store.getters.isShowMapDetail;
    },
    mapDetail() {
      return this.$store.getters.mapDetail;
    },
    isMonitoring() {
      return this.$store.getters.isMonitoring;
    },
    isCameraMonitor() {
      return this.$store.getters.isCameraMonitor;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    isRightSideContents() {
      return (
        this.$store.getters.isRightNavVisible === true &&
        this.$route.path === "/"
      );
    }
  },
  methods: {
    closeMapDetail() {
      const dialog = {
        isShowing: false
      };
      this.$store.commit("SET_MAP_DETAIL", dialog);
    }
  }
};
</script>
