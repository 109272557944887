<template>
  <div class="slider pb-3 pr-2">
    <v-row no-gutters>
      <v-col cols="2" style="min-width: 120px" v-if="!isWind">
        <v-row v-if="selectedRisk.slider" justify="start">
          <v-col style="text-align: center" class="px-0 pt-2">
            <v-btn icon color="black" @click="previous" class="px-3">
              <v-icon large>mdi-skip-previous</v-icon>
            </v-btn>
            <v-btn
              v-if="playing"
              icon
              color="black"
              @click="pause"
              class="px-3"
            >
              <v-icon large>mdi-pause</v-icon>
            </v-btn>
            <v-btn v-else icon color="black" @click="play" class="px-3">
              <v-icon large>mdi-play</v-icon>
            </v-btn>

            <v-btn icon color="black" @click="next" class="px-3">
              <v-icon large>mdi-skip-next</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-slider
          v-if="selectedRisk.slider"
          light
          v-model="position"
          ref="slider"
          :class="{
            'main-slider': true
          }"
          prepend-icon="mdi-clock-outline"
          :max="ticks.length - 1"
          :step="step"
          :tick-labels="tickLabels"
          :ticks="ticksDisplayOption"
          :tick-size="tickSize"
          :hide-details="hideDetails"
          thumb-label="always"
          :thumb-size="25"
          @mouseup="mouseup()"
        >
          <template v-slot:thumb-label>
            <div class="mm-dd">
              {{ selectedDate.local().format("MM/DD") }}
            </div>
            <div class="hh-mm">
              {{ selectedDate.local().format("HH:mm") }}
            </div>
          </template>
        </v-slider>
      </v-col>
      <v-col cols="2" class="date-time-select-area">
        <datetime
          v-model="baseDate"
          input-class="datetime-picker"
          type="datetime"
          :min-datetime="minDate"
          :max-datetime="currentDate"
          @mousedown="showDateTime()"
          @close="closeDateTime()"
        >
          <template slot="button-cancel">
            <v-btn text color="error">
              キャンセル
            </v-btn>
          </template>
          <template slot="button-confirm" slot-scope="scope">
            <span v-if="scope.step === 'date'">
              <v-btn icon color="indigo">
                <v-icon>mdi-clock</v-icon>
              </v-btn>
            </span>
            <span v-else>
              <v-btn text color="success">
                確定
              </v-btn>
            </span>
          </template>
        </datetime>
        <div class="date-control-buttons">
          <v-row no-gutters align="center" justify="center">
            <v-col class="text-right">
              <v-btn
                icon
                color="rgba(55, 55, 55, 0.72)"
                @click="updateBaseDatePreviousDay"
                ><v-icon>mdi-arrow-left-drop-circle</v-icon></v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                fab
                small
                color="warning"
                class="mb-2"
                @click="updateLatest()"
              >
                <v-icon>mdi-update</v-icon>
              </v-btn>
            </v-col>
            <v-col class="text-left">
              <v-btn
                icon
                color="rgba(55, 55, 55, 0.72)"
                @click="updateBaseDateNextDay"
              >
                <v-icon>mdi-arrow-right-drop-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { Risk } from "../../../enums/Risk";
import Vue from "vue";
import { Datetime } from "vue-datetime";

Vue.component("datetime", Datetime);

export default {
  name: "Slider",
  data() {
    return {
      // ==== 表示と関する設定ここから ====
      step: 1,
      hideDetails: true,
      tickSize: 2,
      ticksDisplayOption: "always",

      thumb: {
        size: 40,
        displayOption: "always"
      },
      // この時間ポイントでラーベルが表示される（設定単位：hで設定）
      displayLabelsHours: [-3, -2, -1, 1, 6, 12, 24, 36],
      interval: null,
      Risk: Risk,
      position: 0,
      playing: false,
      minDate: moment.utc("2022/06/30 15:00", "YYYY/MM/DD HH:mm").toISOString(),
      currentDate: moment().toISOString(),
      autoUpdateTimer: null,
      nowPosition: 0
    };
  },
  watch: {
    ticks(ticks) {
      this.pause();
      if (ticks.length === 0) {
        return;
      }
      if (!this.selectedDate) {
        return;
      }
      this.nowPosition = -1;
      ticks.forEach((tick, index) => {
        const tickDate = moment.utc(tick.data.date, "YYYY/MM/DD HH:mm");
        if (tickDate.isSame(this.selectedDate)) {
          this.nowPosition = index;
        }
      });
      this.position = this.nowPosition;
    },
    position(position) {
      if (this.isWind === true) {
        return;
      }
      if (!this.ticks[position]) {
        return;
      }
      this.$store.commit(
        "SET_SELECTED_DATE",
        moment.utc(this.ticks[position].data.date, "YYYY/MM/DD HH:mm")
      );
    }
  },
  computed: {
    baseDate: {
      get() {
        return this.$store.getters.baseDate.toISOString();
      },
      set(date) {
        this.setAutoUpdateTimer(date);
        this.$store.commit("SET_BASE_DATE", moment.utc(date));
      }
    },
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    selectedRiskContents() {
      if (this.selectedRisk.key === Risk.RAIN.key) {
        return this.$store.getters.rainImageInfo;
      } else if (this.selectedRisk.key === Risk.RIVER_FLOODING.key) {
        if (this.$store.getters.riverFloodingInfo.length > 0) {
          return this.$store.getters.riverFloodingInfo[0].images;
        }
        return [];
      } else if (this.selectedRisk.key === Risk.DOSYA.key) {
        if (this.$store.getters.dosyaInfo.images) {
          return this.$store.getters.dosyaInfo.images;
        }
        return [];
      } else if (this.selectedRisk.key === Risk.WIND.key) {
        if (this.$store.getters.wind.timeSeries) {
          return this.$store.getters.wind.timeSeries;
        }
        return [];
      } else if (this.selectedRisk.key === Risk.SNOW.key) {
        if (this.$store.getters.snowInfo.images) {
          return this.$store.getters.snowInfo.images;
        }
        return [];
      } else if (
        this.selectedRisk.key === Risk.KOUZUI.key &&
        this.$store.getters.kouzuiInfo !== undefined
      ) {
        return this.$store.getters.kouzuiInfo;
      } else {
        return [];
      }
    },
    selectedRiskBaseDate() {
      return this.$store.getters.riskBaseDate;
    },
    // ticksの表示用データ
    ticks() {
      // 表示ができるリスクコンテンツがない場合
      if (
        this.selectedRisk.slider === false ||
        this.selectedRiskContents.length < 1
      ) {
        return [];
      }

      let tempTime;
      const ticks = _.map(this.selectedRiskContents, riskContentData => {
        const date = moment.utc(riskContentData.date, "YYYY/MM/DD HH:mm");
        if (this.selectedRiskBaseDate.isSame(date)) {
          return {
            data: riskContentData,
            label: "現在"
          };
        }

        let diff = date.diff(this.selectedRiskBaseDate, "hours", true);
        if (diff <= 0 && this.isSnow !== true) {
          diff = parseInt(diff) - 1;
        } else {
          diff = parseInt(diff);
        }

        if (tempTime === diff) {
          return {
            data: riskContentData,
            label: null
          };
        }

        tempTime = diff;
        const displayLabelsHours = this.isWind
          ? [6, 12, 24, 36]
          : this.isSnow
          ? [-12, -9, -6, -3, 3, 6]
          : [-3, -2, -1, 1, 6, 12, 24, 36];
        if (_.indexOf(displayLabelsHours, diff) !== -1) {
          return {
            data: riskContentData,
            label: diff > 0 ? `+${diff}h` : `${diff}h`
          };
        } else {
          return {
            data: riskContentData,
            label: null
          };
        }
      });

      return ticks;
    },
    tickLabels() {
      return this.ticks.map(tick => tick.label);
    },
    isWind() {
      return this.selectedRisk.key === Risk.WIND.key;
    },
    isSnow() {
      return this.selectedRisk.key === Risk.SNOW.key;
    }
  },
  methods: {
    play() {
      this.interval = setInterval(() => {
        this.next();
      }, 1000);
      this.playing = true;
    },
    pause() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.playing = false;
    },
    next() {
      if (this.position + 1 < this.selectedRiskContents.length) {
        this.position++;
      } else {
        if (this.selectedRisk.key === Risk.RAIN.key) {
          this.position = this.nowPosition;
        } else {
          this.pause();
        }
      }
    },
    previous() {
      if (this.position - 1 >= 0) {
        this.position--;
      }
    },
    updateBaseDate(date) {
      this.setAutoUpdateTimer(date);
      this.$store.commit("SET_BASE_DATE", date);
    },
    updateLatest() {
      this.updateBaseDate(moment.utc());
    },
    updateBaseDatePreviousDay() {
      this.updateBaseDate(
        moment(this.baseDate)
          .utc()
          .clone()
          .subtract(10, "minutes")
      );
    },
    updateBaseDateNextDay() {
      const d = moment(this.baseDate)
        .utc()
        .clone()
        .add(10, "minutes");
      if (d.isSameOrBefore(moment().utc())) {
        this.updateBaseDate(d);
      }
    },
    showDateTime() {
      this.currentDate = moment().toISOString();
      this.$emit("show-datetime");
    },
    closeDateTime() {
      this.$emit("close-datetime");
    },
    setAutoUpdateTimer(date) {
      clearInterval(this.autoUpdateTimer);
      const target = moment.utc(date);
      const now = moment.utc();
      if (target.format("YYYYMMDDHHmm") === now.format("YYYYMMDDHHmm")) {
        console.log("start timer");
        this.autoUpdateTimer = setInterval(() => {
          console.log("auto update");
          this.$store.commit("SET_BASE_DATE", moment.utc());
        }, 5 * 60 * 1000);
      } else {
        console.log("stop timer");
      }
    },
    mouseup() {
      if (this.isWind !== true) {
        return;
      }
      if (this.ticks[this.position]) {
        this.$store.commit(
          "SET_SELECTED_DATE",
          moment.utc(this.ticks[this.position].data.date, "YYYY/MM/DD HH:mm")
        );
      }
    }
  }
};
</script>
<style lang="scss">
@import "../../../common";
.slider {
  pointer-events: auto;
  .v-slider--horizontal .v-slider__thumb-label.primary {
    cursor: ew-resize;
    background-color: #fff !important;
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
    border-radius: 8px;
    transition: none;
    transform: translateY(50%) translateY(-32px) translateX(-50%) !important;
    width: 60px !important;
    height: 40px !important;
    text-align: center;
    font-size: 14px;
    .mm-dd {
      font-size: 14px;
      line-height: 1;
    }
    .hh-mm {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.2;
    }
  }
  .main-slider .v-slider--horizontal .v-slider__thumb-label.primary {
    background-color: $secondary-color !important;
  }
  .v-slider--horizontal .v-slider__thumb-label.primary > * {
    -webkit-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
  }
  .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
    top: 15px;
  }
  .v-slider__tick--filled {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .v-input {
    font-size: 14px;
  }
  .v-slider--horizontal .v-slider__ticks-container {
    top: 50%;
    transform: translateY(-50%);
  }
}
.datetime-picker {
  cursor: pointer;
  border: 0;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  background-color: $point-color-1;
  color: $base-color-1;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 01 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  min-height: 26px;
  max-height: 26px;
  max-width: 125px;
  text-align: center;
}
</style>
<style lang="scss" scoped>
.date-time-select-area {
  position: relative;
  text-align: center;
  min-width: 140px;
  max-width: 140px;
  width: 140px;
  padding-left: 5px;
  padding-right: 5px;
  .date-control-buttons {
    position: absolute;
    bottom: 35px;
    left: 15px;
    text-align: center;
  }
}
</style>
