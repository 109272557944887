import { chatConfig } from "../config";
import { isAuthenticated } from "./cognito";

export const connect = async (isSubscribe = false) => {
  const subscribe = isSubscribe ? 1 : 0;
  const channel = chatConfig.channel;

  const auth = await isAuthenticated();
  const token = auth.idToken.jwtToken;
  const userId = auth.idToken.payload["cognito:username"];

  const url = `${chatConfig.webSocketUrl}?subscribe=${subscribe}&channel=${channel}&userId=${userId}&token=${token}`;
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(url);
    socket.onopen = () => {
      resolve(socket);
    };
    socket.onerror = e => {
      reject(e);
    };
  });
};

export const subscribe = async () => {
  return await connect(true);
};

export const send = async (action, params, onmessage = () => {}) => {
  const socket = await connect();

  socket.onmessage = response => {
    const data = JSON.parse(response.data);
    onmessage(data.data);
    socket.close();
  };

  const payload = {
    action: action,
    ...params
  };
  socket.send(JSON.stringify(payload));

  setTimeout(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close();
    }
  }, 8 * 1000);
};
