import AWS from "aws-sdk";
import { isAuthenticated } from "./cognito";
import { cognitoConfig } from "../config";

AWS.config.region = cognitoConfig.Region;

const getCredentials = async () => {
  try {
    const session = await isAuthenticated();
    if (session === null) return null;
    const user = Object.assign({}, session.idToken.payload);
    if (!user) return null;
    const provider = `cognito-idp.${cognitoConfig.Region}.amazonaws.com/${cognitoConfig.UserPoolId}`;
    const token = session.idToken.jwtToken;
    return new AWS.CognitoIdentityCredentials({
      IdentityPoolId: cognitoConfig.IdentityPoolId,
      Logins: { [provider]: token }
    });
  } catch (e) {
    console.log(e);
    return null;
  }
};

const getDynamoDBClient = async () => {
  const credentials = await getCredentials();
  if (!credentials) return null;
  return new AWS.DynamoDB({ credentials: credentials });
};

export { getDynamoDBClient };
