<template>
  <div class="risk-sub-control d-flex">
    <ObservatoryControlLayer />
    <v-card
      class="align-self-start ml-2 pa-2"
      :width="100"
      v-if="
        Risk.RAIN.key === selectedRisk.key ||
          Risk.DOSYA.key === selectedRisk.key ||
          Risk.SNOW.key === selectedRisk.key ||
          Risk.RIVER_FLOODING.key === selectedRisk.key
      "
    >
      <LayerOpacityControl />
    </v-card>
  </div>
</template>

<script>
import { Risk } from "../../../enums/Risk";
import ObservatoryControlLayer from "./ObservatoryControlLayer";
import LayerOpacityControl from "./LayerOpacityControl";

export default {
  name: "RiskControlLayer",
  components: {
    ObservatoryControlLayer,
    LayerOpacityControl
  },
  data() {
    return {
      Risk: Risk
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    }
  },
  methods: {
    //
  }
};
</script>

<style lang="scss" scoped>
.risk-sub-control {
  width: 300px !important;
}
</style>
<style lang="scss">
.small-radio label {
  font-size: 14px;
  padding-left: 0px;
  margin-left: -4px;
}
.small-radio .v-radio {
  padding: 0px;
}
.small-radio [class*="__ripple"] {
  left: 0;
}
</style>
