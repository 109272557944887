<template>
  <div>
    <v-dialog v-model="isShowDialog" persistent scrollable max-width="400">
      <v-card max-height="60dvh">
        <v-card-text class="ma-0 px-2 py-0">
          <v-list dense>
            <div
              v-for="obs in draftObservatories"
              :key="`${obs.type}-${obs.id}`"
            >
              <v-list-item link @click="selectDraftObservatory(obs)">
                <v-list-item-icon>
                  <v-icon small>{{ obs.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ obs.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isShowDialog = false">
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { OBSERVATORY } from "../../../enums/Type";
import { MapPane } from "../../../enums/Type";
import L from "leaflet";
import "leaflet-markers-canvas";

const ZOOM_ICON_HIDE = 8;
const ZOOM_LABEL_SHOW = 14;

export default {
  name: "ObservatoryLayer",
  data() {
    return {
      obsClicked: false,
      markers: [],
      draftObservatories: [],
      isShowDialog: false,
      tooltipLayer: null,
      markerLayer: null
    };
  },
  computed: {
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    selectedObservatories() {
      return this.$store.getters.selectedObservatories;
    },
    observatories() {
      return this.$store.getters.observatories;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    riskSubControl() {
      return this.$store.getters.riskSubControl;
    },
    map() {
      return this.$store.getters.leafletMap;
    },
    zoom() {
      return Math.floor(this.map.getZoom());
    }
  },
  watch: {
    baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        this.fetchObservatories();
      }
    },
    observatories(newVal, oldVal) {
      if (newVal.length === 0 && oldVal.length === 0) {
        return;
      }
      this.applyFilteredObservatories();
    },
    riskSubControl() {
      this.applyFilteredObservatories();
    },
    zoom(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      if (
        (oldVal <= ZOOM_ICON_HIDE && newVal > ZOOM_ICON_HIDE) ||
        (oldVal > ZOOM_ICON_HIDE && newVal <= ZOOM_ICON_HIDE) ||
        (oldVal < ZOOM_LABEL_SHOW && newVal >= ZOOM_LABEL_SHOW) ||
        (oldVal >= ZOOM_LABEL_SHOW && newVal < ZOOM_LABEL_SHOW)
      ) {
        this.applyFilteredObservatories();
      }
    }
  },
  async created() {
    // tooltip layer
    this.tooltipLayer = L.layerGroup();
    this.tooltipLayer.id = "tooltiplayer";
    this.map.addLayer(this.tooltipLayer);

    // marker layer
    this.markerLayer = new L.MarkersCanvas({
      pane: MapPane.ObservatoryMarkersLayer.name
    });
    this.markerLayer.id = "observatorylayer";
    this.map.addLayer(this.markerLayer);

    this.$store.commit("SET_IS_LOADING", true);
    await this.fetchObservatories();

    this.map.on("click", this.clickMapMarkers);
    this.map.on("moveend", this.drawTooltips);
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("SET_IS_LOADING", false);
    }, 1500);
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off("moveend", this.drawTooltips);
      this.map.off("click", this.clickMapMarkers);
    }
    this.map.eachLayer(layer => {
      if (layer.id === "observatorylayer" || layer.id === "tooltiplayer") {
        this.map.removeLayer(layer);
      }
    });
    this.$store.commit("SET_USAGE_GUIDE", {
      usageGuide: {
        title: null,
        colors: []
      }
    });
  },
  methods: {
    async fetchObservatories() {
      await this.$store.dispatch("FETCH_OBSERVATORIES");
    },
    addTooltip(obs, marker) {
      let direction = "top";
      let offset = [0, -15];
      if (
        (obs.code === "3841_191" && obs.type === 4) ||
        (obs.code === "3841_194" && obs.type === 4)
      ) {
        direction = "right";
        offset = [15, 0];
      } else if (
        (obs.code === "9999_2" && obs.type === 30) ||
        (obs.code === "9999_3" && obs.type === 30)
      ) {
        direction = "left";
        offset = [-15, 0];
      }
      var tooltip = L.tooltip({
        direction: direction,
        offset: offset,
        permanent: marker === null
      });
      tooltip.setContent(obs.name);
      tooltip.setLatLng([obs.lat, obs.lng]);
      if (marker === null) {
        tooltip.addTo(this.tooltipLayer);
      } else {
        marker.bindTooltip(tooltip);
      }
    },
    drawTooltips() {
      if (this.zoom >= ZOOM_LABEL_SHOW) {
        this.tooltipLayer.clearLayers();
        const bounds = this.map.getBounds();
        this.markers.forEach(marker => {
          const latlng = marker.getLatLng();
          if (bounds.contains(latlng) === true) {
            this.addTooltip(marker.options.observatoryInfo, null);
          }
        });
      }
    },
    applyFilteredObservatories() {
      if (this.tooltipLayer === null || this.markerLayer === null) {
        return;
      }
      // Clear Layer
      this.tooltipLayer.clearLayers();
      this.markers.forEach(m => this.markerLayer.removeMarker(m, false));
      this.markers = [];
      // Filterd
      const filterdObs = this.observatories.filter(obs => {
        return this.riskSubControl.observatory.includes(obs.type);
      });
      if (this.zoom <= ZOOM_ICON_HIDE || filterdObs.length === 0) {
        this.markerLayer.redraw();
        return false;
      }
      // Add Markers
      filterdObs.forEach(obs => {
        if (obs.lat === undefined || obs.lng === undefined) {
          return;
        }

        if (
          obs.type === OBSERVATORY.FLOODSENSOR.code &&
          obs.takeoSinsui !== true
        ) {
          return;
        }

        const iconUrl = require(`@/assets/images/observatory-icons/${this.getIconUrl(
          obs
        )}`);
        let iconSize = Math.floor(this.zoom * 1.9);
        const anchor = [iconSize * 0.5, iconSize * 0.5];
        let adjusted = false;
        if (obs.type === 1) {
          const finded = filterdObs.find(
            o =>
              o.type === 4 &&
              Math.abs(o.lat - obs.lat) <= 0.0001 &&
              Math.abs(o.lng - obs.lng) <= 0.0001
          );
          if (finded !== undefined) {
            anchor[0] -= iconSize;
            adjusted = true;
          }
        } else if (obs.type === 30) {
          const finded = filterdObs.find(
            o =>
              o.type !== obs.type &&
              o.type !== 50 &&
              o.type !== 60 &&
              Math.abs(o.lat - obs.lat) <= 0.0001 &&
              Math.abs(o.lng - obs.lng) <= 0.0001
          );
          if (finded !== undefined) {
            anchor[1] -= iconSize;
            adjusted = true;
          }
        } else if (obs.type === 50) {
          const finded = filterdObs.find(
            o =>
              o.type !== obs.type &&
              Math.abs(o.lat - obs.lat) <= 0.0001 &&
              Math.abs(o.lng - obs.lng) <= 0.0001
          );
          if (finded !== undefined) {
            anchor[0] += iconSize;
            adjusted = true;
          }
        }
        const latLng = L.latLng([obs.lat, obs.lng]);
        const icon = L.divIcon({
          iconSize: [iconSize, iconSize],
          iconAnchor: anchor,
          iconUrl: iconUrl
        });
        const marker = L.marker(latLng, {
          icon: icon,
          observatoryInfo: obs,
          adjusted: adjusted
        });
        if (this.zoom < ZOOM_LABEL_SHOW) {
          this.addTooltip(obs, marker);
        }
        this.markers.push(marker);
      });
      if (this.markers.length === 0) {
        return;
      }
      // add markders
      this.markerLayer.addMarkers(this.markers);
      // レンダリング位置がずれる対策
      this.map.panTo(this.map.getCenter());
      // Tooltips
      this.drawTooltips();
    },
    getIconUrl(observatoryInfo) {
      switch (observatoryInfo.type) {
        case OBSERVATORY.STAGE.code:
          switch (observatoryInfo.status) {
            case "deficit":
              return "stage-deficit.png";
            case "normal":
              return "stage-normal.png";
            case "standby":
              return "stage-standby.png";
            case "warning":
              return "stage-warning.png";
            case "evacuation":
              return "stage-evacuation.png";
            case "dangerous":
              return "stage-dangerous.png";
            case "outbreak":
              return "stage-outbreak.png";
            default:
              return "stage-default.png";
          }
        case OBSERVATORY.RAIN.code:
          switch (observatoryInfo.status) {
            case "deficit":
              return "rain-deficit.png";
            case "0":
              return "rain-0.png";
            case "1":
              return "rain-1.png";
            case "2":
              return "rain-2.png";
            case "3":
              return "rain-3.png";
            case "4":
              return "rain-4.png";
            default:
              return "rain-default.png";
          }
        case OBSERVATORY.DAM.code:
          switch (observatoryInfo.status) {
            case "deficit":
              return "dam-deficit.png";
            case "normal":
              return "dam-normal.png";
            default:
              return "dam-default.png";
          }
        case OBSERVATORY.CAMERA.code:
          return "camera-normal.png";
        case OBSERVATORY.LIVECAMERA.code:
          return "camera-iris.png";
        case OBSERVATORY.FLOODSENSOR.code:
          return "floodsensor-normal.png";
        case OBSERVATORY.TIDE.code:
          switch (observatoryInfo.status) {
            case "deficit":
              return "tide-deficit.png";
            case "normal":
              return "tide-normal.png";
            default:
              return "tide-normal.png";
          }
      }
    },
    clickMapMarkers(e) {
      // 二重起動防止
      if (this.obsClicked) {
        return;
      }
      this.obsClicked = true;
      setTimeout(() => {
        this.obsClicked = false;
      }, 500);

      const p = this.map.latLngToLayerPoint(e.latlng);
      const southWest = this.map.layerPointToLatLng([p.x - 20, p.y + 20]);
      const northEast = this.map.layerPointToLatLng([p.x + 20, p.y - 20]);
      const bounds = new L.latLngBounds(southWest, northEast);
      const targets = this.observatories.filter(obs => {
        return (
          this.riskSubControl.observatory.includes(obs.type) &&
          bounds.contains(L.latLng(obs.lat, obs.lng))
        );
      });
      if (targets.length === 0) {
        return;
      }
      if (targets.length === 1) {
        this.$store.dispatch("FETCH_SELECTED_OBSERVATORY_DATA", targets[0]);
        return;
      }
      this.draftObservatories = JSON.parse(JSON.stringify(targets));
      this.draftObservatories.forEach(obs => {
        switch (obs.type) {
          case OBSERVATORY.RAIN.code:
            obs.icon = "mdi-checkbox-blank-circle";
            break;
          case OBSERVATORY.STAGE.code:
            obs.icon = "mdi-triangle";
            break;
          case OBSERVATORY.CAMERA.code:
            obs.icon = "mdi-camera";
            break;
          case OBSERVATORY.LIVECAMERA.code:
            obs.icon = "mdi-camera-iris";
            break;
          case OBSERVATORY.DAM.code:
            obs.icon = "mdi-car-windshield";
            break;
          case OBSERVATORY.FLOODSENSOR.code:
            obs.icon = "mdi-waves";
            break;
          case OBSERVATORY.TIDE.code:
            obs.icon = "mdi-pentagon";
            break;
        }
      });
      setTimeout(() => {
        this.isShowDialog = true;
      }, 300);
    },
    selectDraftObservatory(observatoryInfo) {
      this.$store.dispatch("FETCH_SELECTED_OBSERVATORY_DATA", observatoryInfo);
      this.isShowDialog = false;
    }
  }
};
</script>
