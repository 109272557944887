<template>
  <div style="height: 100%; background-color: #44485C">
    <v-card class="d-flex align-center" height="34" color="transparent" tile>
      <v-img
        class="mx-2"
        contain
        width="20"
        height="20"
        max-height="20"
        max-width="20"
        :src="require('@/assets/images/png/mobile-logo.png')"
      ></v-img>
      <h4 class="white--text text-no-wrap">
        {{ `${appTitle} - チャット` }}
      </h4>
    </v-card>
    <div style="height: calc(100dvh - 42px)">
      <ChatContainer />
    </div>
  </div>
</template>
<script>
import ChatContainer from "../components/chat/ChatContainer";
export default {
  name: "MobileChat",

  components: {
    ChatContainer
  },

  data() {
    return {
      //
    };
  },

  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    }
  },

  created() {
    //
  },

  methods: {
    //
  }
};
</script>

<style lang="scss" scoped>
//
</style>
