<template>
  <div class="main">
    <!-- Loading -->
    <div class="loading-mask" v-if="isLoading">
      <div class="loading-message">ページを読み込んでいます...</div>
      <VueLoading type="spin" color="#eee" :size="loadingSize" />
    </div>

    <!-- Map -->
    <div class="mobile-base-map" :class="selectedRisk.slider ? '' : 'noslider'">
      <mobile-base-map>
        <template v-slot:bottomleft>
          <v-btn
            rounded
            small
            style="pointer-events: painted"
            @click="displayMenu()"
          >
            Menu
          </v-btn>
        </template>
      </mobile-base-map>
    </div>

    <!-- Title -->
    <v-card
      class="mobile-header d-flex align-center"
      height="34"
      color="#44485C"
      tile
    >
      <v-img
        class="mx-2"
        contain
        width="20"
        height="20"
        max-height="20"
        max-width="20"
        :src="require('@/assets/images/png/mobile-logo.png')"
      ></v-img>
      <h5 class="white--text text-no-wrap">
        {{ appTitle }}
      </h5>
      <v-btn icon @click="displayMenu" class="ml-auto">
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
    </v-card>

    <!-- Slider -->
    <div v-if="selectedRisk.slider" class="mobile-footer">
      <MobileTimeSeriesControl />
    </div>

    <!-- Menu -->
    <MapMenu v-if="isShowMenu" @close="isShowMenu = false" />

    <!-- Details -->
    <v-dialog
      v-model="isShowMapDetail"
      width="95wh"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-toolbar dense flat color="primary">
          <v-card class="body-2 white--text" color="primary" flat>
            {{ mapDetail.title }}
          </v-card>
          <v-spacer />
          <v-btn dark icon @click="closeMapDetail">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0" style="max-height: 80dvh; overflow-x: auto;">
          <template v-if="mapDetail.contents === 'stage-detail'">
            <StageDetail />
          </template>
          <template v-if="mapDetail.contents === 'rainfall-detail'">
            <RainDetail />
          </template>
          <template v-if="mapDetail.contents === 'camera-detail'">
            <CameraDetail />
          </template>
          <template v-if="mapDetail.contents === 'live-camera-detail'">
            <LiveCameraDetail />
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Risk } from "../enums/Risk";
import MobileBaseMap from "../components/base-map/MobileBaseMap";
import MobileTimeSeriesControl from "../components/base-map/mobileLayers/MobileTimeSeriesControl";
import MapMenu from "../components/base-map/mobileLayers/MapMenu";
import StageDetail from "../components/base-map/details/StageDetail";
import RainDetail from "../components/base-map/details/RainDetail";
import CameraDetail from "../components/base-map/details/CameraDetail";
import LiveCameraDetail from "../components/base-map/details/LiveCameraDetail";
import { VueLoading } from "vue-loading-template";

export default {
  name: "Dashboard",
  components: {
    VueLoading,
    MobileTimeSeriesControl,
    MobileBaseMap,
    MapMenu,
    StageDetail,
    RainDetail,
    CameraDetail,
    LiveCameraDetail
  },
  data() {
    return {
      isShowMenu: false,
      loadingSize: { width: "80px", height: "80px" }
    };
  },
  created() {
    this.$store.commit("SET_SELECTED_RISK", Risk.RAIN);
  },
  computed: {
    isShowMapDetail() {
      return this.$store.getters.isShowMapDetail;
    },
    mapDetail() {
      return this.$store.getters.mapDetail;
    },
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    appTitle() {
      return process.env.VUE_APP_TITLE;
    }
  },
  methods: {
    closeMapDetail() {
      const dialog = {
        isShowing: false
      };
      this.$store.commit("SET_MAP_DETAIL", dialog);
    },
    displayMenu() {
      this.isShowMenu = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100dvh;
}
.mobile-base-map {
  width: 100%;
  height: 100%;
  padding-top: 34px;
  padding-bottom: 60px;
  &.noslider {
    padding-bottom: 0;
  }
}
.mobile-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.mobile-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
}
.loading-mask {
  z-index: 4000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(88, 88, 88, 0.5);
  text-align: center;
}
.loading-message {
  margin-top: 40%;
  font-size: 0.9em;
  color: rgb(30, 30, 30);
}
</style>

<style lang="scss">
.vdatetime-popup {
  z-index: 2000 !important;
}
</style>

<style lang="scss" scoped>
.basetime-control {
  top: 55px;
  right: 10px;
  position: absolute;
  pointer-events: none;
  z-index: 1500;
}
</style>
