<template>
  <v-card
    color="grey lighten-3"
    height="100%"
    class="chat-container pt-3 mx-2"
    tile
  >
    <!-- messages -->
    <v-card
      color="transparent"
      :height="messageAreaHeight"
      class="px-3 overflow-y-auto chat-message-area"
      tile
      flat
      width="320"
      @scroll="handleScroll"
    >
      <div
        class="mb-3"
        :class="row.userId === userId ? 'my-message' : 'others-message'"
        v-for="(row, index) in messages"
        :key="index"
      >
        <div class="message-body">
          <div class="message-text" v-if="row.content">{{ row.content }}</div>
          <div class="message-photo" v-if="row.url">
            <v-img
              :src="row.thumbUrl ? row.thumbUrl : row.url"
              width="160"
              height="100"
              class="message-photo"
              @click="showPhoto(row)"
            />
          </div>
        </div>
        <div class="caption">
          {{ row.jst }}
          <span class="pl-2" v-if="row.userId !== userId">
            {{ row.userName }}
          </span>
        </div>
      </div>
    </v-card>

    <!-- input -->
    <v-card class="ma-2" flat>
      <input
        type="file"
        name="image"
        accept="image/*"
        ref="photoPicker"
        hidden
        @change="pickedPhoto"
      />
      <v-textarea
        ref="inputMessage"
        placeholder="メッセージ"
        flat
        dense
        auto-grow
        rows="1"
        outlined
        hide-details
        v-model="inputMessage"
        prepend-inner-icon="mdi-image"
        :append-icon="inputMessage !== '' ? 'mdi-send' : ''"
        @click:append="sendMessage()"
        @click:prepend-inner="takePhoto"
      />
    </v-card>

    <v-dialog v-model="photoDialog" width="300">
      <v-card class="pa-2">
        <v-img
          v-if="inputFile"
          :src="getInputFileUrl()"
          height="240"
          width="300"
          contain
        ></v-img>
        <div class="d-flex pa-3 mt-2">
          <v-text-field
            dense
            label="緯度"
            v-model="lat"
            class="mr-1"
            hide-details
          />
          <v-text-field
            dense
            label="経度"
            v-model="lng"
            class="ml-1"
            hide-details
          />
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" text @click="photoDialog = false">
            キャンセル
          </v-btn>
          <v-btn small color="primary" @click="sendPhoto()"> 送信 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { BRANCH_OFFICE } from "../../enums/BranchOffice";
import exifr from "exifr";
import * as websocket from "../../utils/websocket";
import { chatConfig } from "../../config";
export default {
  name: "ChatContainer",
  data() {
    return {
      subscribeSocket: null,
      messages: [],
      fetchLastKey: "",
      isFetchFinished: false,
      inputMessage: "",
      inputFile: null,
      currentLocation: {
        lat: BRANCH_OFFICE[0].lat,
        lng: BRANCH_OFFICE[0].lng
      },
      lat: BRANCH_OFFICE[0].lat,
      lng: BRANCH_OFFICE[0].lng,
      photoDialog: false,
      messageAreaHeight: "calc(100% - 80px)",
      reconnectWebsocket: true
    };
  },

  computed: {
    userId() {
      return this.$store.state.user.id;
    },
    userName() {
      const user = this.$store.state.user;
      return user.family_name + " " + user.given_name;
    },
    map() {
      return this.$store.getters.leafletMap;
    }
  },

  watch: {
    inputMessage() {
      this.refreshInputMessageHeight();
    }
  },

  created() {
    this.getCurrentLocation();
  },

  mounted() {
    this.refreshInputMessageHeight();
    this.fetchMessages();
    this.subscribe();
  },

  beforeDestroy() {
    if (
      this.subscribeSocket &&
      this.subscribeSocket.readyState === WebSocket.OPEN
    ) {
      this.reconnectWebsocket = false;
      this.subscribeSocket.close();
    }
    this.$store.commit("SET_CHAT_THUMBNAILS", []);
  },

  methods: {
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.currentLocation.lat = position.coords.latitude;
          this.currentLocation.lng = position.coords.longitude;
        },
        error => {
          console.log(error);
        }
      );
    },

    handleScroll(e) {
      if (e.target.scrollTop !== 0) {
        return;
      }
      this.fetchMessages();
    },

    refreshInputMessageHeight() {
      const h = this.$refs.inputMessage.$el.clientHeight + 20;
      this.messageAreaHeight = `calc(100% - ${h}px)`;
    },

    getInputFileUrl() {
      if (!this.inputFile) return "";
      return URL.createObjectURL(this.inputFile);
    },

    async subscribe() {
      this.subscribeSocket = await websocket.subscribe();
      this.subscribeSocket.onclose = () => {
        console.log("websocket unsubscribed");
        if (this.reconnectWebsocket) {
          this.subscribe();
        }
      };
      this.subscribeSocket.onmessage = e => {
        const response = JSON.parse(e.data);
        if (response.action !== "subscribe") {
          return;
        }
        this.messages.push(response.data);
        this.scrollToEnd();
      };
      console.log("websocket subscribed");
    },

    async fetchMessages() {
      if (this.isFetchFinished) return;
      const params = {
        channel: chatConfig.channel,
        date: "",
        lastKey: this.messages.length === 0 ? "" : this.fetchLastKey
      };
      websocket.send("fetch", params, e => {
        this.messages = e.messages.concat(this.messages);
        if (e.lastKey) {
          this.fetchLastKey = e.lastKey;
        } else {
          this.isFetchFinished = true;
        }
        if (this.messages.length > 0) {
          this.scrollToEnd();
        }
      });
    },

    scrollToEnd() {
      this.$nextTick(() => {
        const container = this.$el.querySelector(".chat-message-area");
        container.scrollTop = container.scrollHeight;
      });
    },

    takePhoto() {
      const elm = this.$refs.photoPicker;
      elm.click();
    },

    async pickedPhoto(e) {
      if (!e) {
        this.inputFile = null;
        return;
      }
      const file = e.target.files[0];
      this.inputFile = file;
      this.photoDialog = true;

      this.lat = this.currentLocation.lat;
      this.lng = this.currentLocation.lng;

      // 画像の緯度経度を取得
      try {
        const exifLatLng = await exifr.gps(file);
        if (exifLatLng && exifLatLng.latitude && exifLatLng.longitude) {
          this.lat = Number(exifLatLng.latitude.toFixed(7));
          this.lng = Number(exifLatLng.longitude.toFixed(7));
        }
      } catch (e) {
        console.log(e);
      }
    },

    async putPresignedUrl(url, file) {
      if (!url || !file) return null;
      return axios({
        method: "PUT",
        url: url,
        headers: {
          "Content-Type": file.type
        },
        data: file
      })
        .then(response => {
          return response.status;
        })
        .catch(err => {
          console.log(err);
          return null;
        });
    },

    sendMessage(s3Key = "") {
      if (this.inputMessage === "" && s3Key === "") return;
      const params = {
        channel: chatConfig.channel,
        userId: this.userId,
        userName: this.userName,
        content: s3Key ? "" : this.inputMessage,
        s3Key: s3Key
      };
      if (s3Key) {
        params.lat = this.lat;
        params.lng = this.lng;
      }
      websocket.send("save", params, e => {
        if (e.success === true) {
          this.inputMessage = "";
          this.inputFile = null;
          this.photoDialog = false;
          setTimeout(() => {
            this.refreshInputMessageHeight();
          }, 100);
        }
      });
    },

    sendPhoto() {
      if (!this.inputFile) return;

      const params = {
        channel: chatConfig.channel,
        contentType: this.inputFile.type
      };
      websocket.send("upload", params, async e => {
        const s3Key = e.key;
        const presignedRes = await this.putPresignedUrl(e.url, this.inputFile);
        if (presignedRes === 200) {
          this.sendMessage(s3Key);
        }
      });
    },

    showPhoto(targetRow) {
      if (!targetRow.url) return;
      const row = { ...targetRow };
      if (!row.lat || !row.lng) {
        row.lat = BRANCH_OFFICE[0].lat;
        row.lng = BRANCH_OFFICE[0].lng;
      }
      const rows = [...this.$store.getters.chatThumbnails];
      if (!rows.some(r => r.id === row.id)) {
        rows.push(row);
        this.$store.commit("SET_CHAT_THUMBNAILS", rows);
      }
      let zoom = this.map.getZoom();
      if (zoom < 16) {
        zoom = 16;
      }
      this.map.setView([row.lat, row.lng], zoom);
    }
  }
};
</script>

<style lang="scss" scoped>
.camera-dialog {
  box-shadow: none !important;
}
.message-body {
  border-radius: 8px;
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  max-width: 70%;
  position: relative;
  text-align: left;
  padding: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  .message-text {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  .message-photo {
    overflow: hidden;
    width: 100%;
    height: 100px;
    cursor: pointer;
  }
}

.others-message {
  text-align: left;
  width: 100%;
  .message-body {
    color: black;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
  }
}

.my-message {
  text-align: right;
  width: 100%;
  .message-body {
    color: white;
    background-color: #4967a3;
  }
}
</style>

<style lang="scss">
.chat-container .v-textarea textarea {
  font-size: 12px !important;
}
</style>
