<template>
  <l-geo-json
    :geojson="geojson"
    :options="{ style: styleFunction }"
  ></l-geo-json>
</template>
<script>
import { LGeoJson } from "vue2-leaflet";
import axios from "axios";
import moment from "moment";
import * as axiosHelper from "../../../utils/axiosHelper";
export default {
  name: "KouzuiLayer",
  components: {
    LGeoJson
  },
  inject: {
    baseMapData: {
      default: []
    }
  },
  data() {
    return {
      geojson: null
    };
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    kouzuiInfo() {
      return this.$store.getters.kouzuiInfo;
    },
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    selectedDateRiskData() {
      if (!this.selectedDate) {
        return;
      }
      if (!this.kouzuiInfo || this.kouzuiInfo.length === 0) {
        return;
      }
      return this.kouzuiInfo.find(info =>
        this.selectedDate.isSame(moment.utc(info.date, "YYYY/MM/DD HH:mm"))
      );
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },
  watch: {
    async selectedDateRiskData() {
      if (this.selectedDateRiskData) {
        let [err, json] = await this.loadJson(this.selectedDateRiskData.url);
        if (err) throw new Error("load failed : /river-risk");
        this.geojson = json;
      }
    },
    baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        this.loadData();
      }
    }
  },
  created() {
    this.loadData();
    this.$store.commit("SET_USAGE_GUIDE", [
      {
        title: "洪水警報の危険度分布",
        colors: [
          { color: "#10000e", value: "災害切迫【５】" },
          { color: "#a5009c", value: "危険　　【４】" },
          { color: "#ff251b", value: "警戒　　【３】" },
          { color: "#f1e539", value: "注意　　【２】" },
          { color: "#00fffe", value: "今後の情報等に留意" }
        ]
      },
      {
        title: "指定河川洪水予報",
        colors: [
          { color: "#10000e", value: "氾濫発生情報【５】" },
          { color: "#a5009c", value: "氾濫危険情報【４】" },
          { color: "#ff251b", value: "氾濫警戒情報【３】" },
          { color: "#f1e539", value: "氾濫注意情報【２】" },
          { color: "#00fffe", value: "発表なし" }
        ]
      }
    ]);
  },
  beforeDestroy() {
    this.$store.commit("SET_USAGE_GUIDE", {
      title: "",
      colors: []
    });
  },
  methods: {
    async loadData() {
      this.$store.commit("SET_KOUZUI_INFO", []);
      const baseDate = this.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");
      const params = {
        date: baseDate
          .clone()
          .utc()
          .format("YYYY/MM/DD HH:mm")
      };
      const response = await axiosHelper.get("river-risk", params);
      if (response === null) {
        return;
      }
      this.$store.commit("SET_KOUZUI_INFO", response.riverRisks);
      this.$store.commit(
        "SET_SELECTED_DATE",
        moment.utc(response.baseDate, "YYYY/MM/DD HH:mm")
      );
    },
    styleFunction(feature) {
      const style = {
        weight: 3,
        color: "#4ffeff",
        opacity: 1,
        fillColor: "#4ffeff",
        fillOpacity: 0.2,
        "fill-outline-color": "rgba(255,255,255,0.0)",
        outlineColor: "blue"
      };

      if (feature.properties.FLOODRISK) {
        switch (parseInt(feature.properties.FLOODRISK)) {
          case 1:
            style.color = "rgba(242,231,0)";
            style.fillColor = "rgba(242,231,0)";
            break;
          case 2:
            style.color = "rgba(255,40,0)";
            style.fillColor = "rgba(255,40,0)";
            break;
          case 3:
            style.color = "rgba(170,0,170)";
            style.fillColor = "rgba(170,0,170)";
            break;
          case 4:
            style.color = "rgba(12,0,12)";
            style.fillColor = "rgba(12,0,12)";
            break;
        }
      }
      if (feature.properties.FLOODFCST) {
        style.weight = 10;
        switch (parseInt(feature.properties.FLOODFCST)) {
          case 2:
            style.color = "rgba(242,231,0)";
            style.fillColor = "rgba(242,231,0)";
            break;
          case 3:
            style.color = "rgba(255,40,0)";
            style.fillColor = "rgba(255,40,0)";
            break;
          case 4:
            style.color = "rgba(170,0,170)";
            style.fillColor = "rgba(170,0,170)";
            break;
          case 5:
            style.color = "rgba(12,0,12)";
            style.fillColor = "rgba(12,0,12)";
            break;
        }
      }
      return style;
    },
    async loadJson(url) {
      return axios
        .create({
          responseType: "json"
        })
        .get(url)
        .then(response => {
          return [null, response.data];
        })
        .catch(err => [err]);
    }
  }
};
</script>

<style scoped></style>
