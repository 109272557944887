var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.url)?_c('div',[_vm._l((_vm.slicedImages),function(img,index){return [(img.isInDeviceViewPort)?_c('LMarker',{key:index,attrs:{"lat-lng":[img.markerLat, img.markerLng]}},[_c('LIcon',{attrs:{"icon-anchor":[0, 0]}},[_c('div',{style:({
            width: img.divWidth + 'px',
            height: img.divHeight + 'px',
            overflow: 'hidden',
            'pointer-events': 'none',
            borderBottom: _vm.bordersColor ? ("1px solid " + _vm.bordersColor) : 'none'
          })},[_c('img',{staticClass:"riskImage",style:({
              transform: ("translate(0," + (img.translateY) + "px)"),
              width: img.imgWidth + 'px',
              height: img.imgHeight + 'px',
              'pointer-events': 'none',
              opacity: _vm.opacity
            }),attrs:{"alt":"rainfallImage","src":_vm.url}})])])],1):_vm._e()]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }