<template>
  <div style="z-index: 20000 !important">
    <SlicedImageLayer
      v-for="(area, index) in areas"
      :key="index"
      :bounds="area.bounds"
      :images="area.images"
    />
  </div>
</template>
<script>
import { Risk } from "../../../enums/Risk";
import SlicedImageLayer from "./SlicedImageLayer";
export default {
  name: "river-flooding-layer",
  components: { SlicedImageLayer },
  data() {
    return {
      Risk,
      multiAreaRiskLayers: []
    };
  },
  watch: {
    baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        this.loadData();
      }
    }
  },
  computed: {
    leafletMap() {
      return this.$store.getters.leafletMap;
    },
    areas() {
      return this.$store.getters.riverFloodingInfo.map(area => area);
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    types() {
      return this.$store.getters.riverFloodingOptions.types.value;
    },
    viewportBounds() {
      return this.leafletMap.getBounds();
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("FETCH_RIVER_FLOODING");
    }
  },
  mounted() {
    this.$store.commit("SET_USAGE_GUIDE", {
      title: "浸水ﾘｽｸ(m)",
      colors: [
        { color: "#fd2100", value: "高" },
        { color: "#fbf43c", value: "" },
        { color: "#2595fd", value: "" },
        { color: "#FFFFFF", value: "低" }
      ]
    });
  }
};
</script>

<style scoped></style>
