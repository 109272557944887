<template>
  <div class="stage-level-detail pa-3">
    <div class="caption text--secondary">
      {{ selectedObservatory.address }}
    </div>
    <div class="caption text--secondary">
      {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
    </div>
    <div
      class="caption text--secondary text-right"
      v-if="selectedObservatory.source"
    >
      {{ `データ提供: ${selectedObservatory.source}` }}
    </div>

    <v-card height="10" tile flat></v-card>
    <div class="d-flex my-1" v-if="isChartVisible">
      <div class="caption ml-2">（{{ levelUnit }}）</div>
      <div class="caption ml-auto" v-if="hasFloodIndex">流域雨量指数</div>
    </div>
    <StageChart
      v-if="isChartVisible"
      :height="240"
      :selected-observatory="selectedObservatory"
      :displayHours="displayHours"
      :levelUnit="levelUnit"
      :standardLevelType="standardLevelType"
      :chartWaterBoxLevel="chartWaterBoxLevel"
    />
    <div class="mt-3" v-if="isChartVisible && isStage">
      <template v-if="hasFloodIndex">
        <v-btn-toggle v-model="standardLevelType" mandatory dense class="mb-1">
          <v-btn small value="stage">
            基準水位
          </v-btn>
          <v-btn small value="floodIndex">
            流域雨量指数基準値
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-else>
        <div class="caption">基準水位</div>
      </template>
      <div
        class="caption d-flex"
        v-for="std in standardLevels"
        :key="std.label"
      >
        <v-card width="70" flat tile>
          <div
            class="std-color ml-8"
            :style="{ 'border-bottom-color': std.color }"
          ></div>
        </v-card>
        <v-card width="100" flat tile>{{ std.label }}</v-card>
        <v-card width="100" flat tile class="text-right">
          {{
            std.level == undefined
              ? "---"
              : standardLevelType === "stage"
              ? std.level.toFixed(2) + " " + levelUnit
              : std.level.toFixed(1)
          }}
        </v-card>
      </div>
    </div>
    <v-simple-table dense class="mt-3" v-if="!isDeficit">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">日時</th>
            <th class="text-right">水位 ({{ levelUnit }})</th>
            <th class="text-right" v-if="hasFloodIndex">流域雨量指数</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(ts, index) in timeSeries"
            :key="index"
            :class="{ predict: ts.type === 'forecast' }"
          >
            <td>{{ getJst(ts.date) }}</td>
            <td class="text-right">
              {{ ts.level === undefined ? "---" : ts.level.toFixed(2) }}
            </td>
            <td class="text-right" v-if="hasFloodIndex">
              {{
                ts.flood_index === undefined ? "---" : ts.flood_index.toFixed(1)
              }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="mt-5 caption" v-if="isDeficit">
      <template
        v-if="selectedObservatory.kiki === true && chartWaterBoxLevel !== null"
      >
        この観測所は観測開始水位以下です。
      </template>
      <template v-else>
        この観測所は欠測しています。
      </template>
    </div>
  </div>
</template>

<script>
import StageChart from "./StageChart";
import { OBSERVATORY, STAGES } from "../../../enums/Type";
import moment from "moment";

export default {
  name: "StageDetail",
  components: {
    StageChart
  },
  data() {
    return {
      standardLevelType: "stage"
    };
  },
  computed: {
    displayHours() {
      return this.hasFloodIndex ? 6 : 1;
    },
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    isStage() {
      return (
        this.$store.getters.selectedObservatory.type === OBSERVATORY.STAGE.code
      );
    },
    isFloodSensor() {
      return (
        this.$store.getters.selectedObservatory.type ===
        OBSERVATORY.FLOODSENSOR.code
      );
    },
    isTide() {
      return (
        this.$store.getters.selectedObservatory.type === OBSERVATORY.TIDE.code
      );
    },
    hasFloodIndex() {
      return this.selectedObservatory.timeSeries.some(
        v => v.flood_index !== undefined
      );
    },
    levelUnit() {
      if (this.isFloodSensor === true) {
        return "cm";
      }
      return this.selectedObservatory.kind === 9 ? "TP.m" : "m";
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    isDeficit() {
      return this.timeSeries.length === 0;
    },
    standardLevels() {
      const levels = [];

      if (this.standardLevelType === "floodIndex") {
        levels.push({
          label: "基準Ⅳ",
          color: STAGES.OUTBREAK.color,
          level: this.selectedObservatory.f_index_level4
        });
        levels.push({
          label: "基準Ⅲ",
          color: STAGES.DANGEROUS.color,
          level: this.selectedObservatory.f_index_level3
        });
        levels.push({
          label: "基準Ⅱ",
          color: STAGES.EVACUATION.color,
          level: this.selectedObservatory.f_index_level2
        });
        levels.push({
          label: "基準Ⅰ",
          color: STAGES.WARNING.color,
          level: this.selectedObservatory.f_index_level1
        });
        return levels;
      }

      if (this.selectedObservatory.kiki === true) {
        levels.push({
          label: "氾濫発生水位",
          color: STAGES.OUTBREAK.color,
          level: this.selectedObservatory.outbreakLevel
        });
        levels.push({
          label: "氾濫危険水位",
          color: STAGES.DANGEROUS.color,
          level: this.selectedObservatory.dangerousLevel
        });
        levels.push({
          label: "観測開始水位",
          color: STAGES.STANDBY.color,
          level: this.selectedObservatory.standbyLevel
        });
      } else {
        levels.push({
          label: STAGES.OUTBREAK.label,
          color: STAGES.OUTBREAK.color,
          level: this.selectedObservatory.outbreakLevel
        });
        levels.push({
          label: STAGES.DANGEROUS.label,
          color: STAGES.DANGEROUS.color,
          level: this.selectedObservatory.dangerousLevel
        });
        levels.push({
          label: STAGES.EVACUATION.label,
          color: STAGES.EVACUATION.color,
          level: this.selectedObservatory.evacuationLevel
        });
        levels.push({
          label: STAGES.WARNING.label,
          color: STAGES.WARNING.color,
          level: this.selectedObservatory.warningLevel
        });
        levels.push({
          label: STAGES.STANDBY.label,
          color: STAGES.STANDBY.color,
          level: this.selectedObservatory.standbyLevel
        });
      }
      return levels;
    },
    timeSeries() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      } else {
        return this.selectedObservatory.timeSeries;
      }
    },
    chartWaterBoxLevel() {
      const target = this.$store.getters.observatories.find(
        o =>
          o.id === this.selectedObservatory.id &&
          o.kiki === true &&
          o.chartWaterBoxLevel !== undefined
      );
      return target === undefined ? null : target.chartWaterBoxLevel;
    },
    isChartVisible() {
      return !this.isDeficit || this.chartWaterBoxLevel !== null;
    }
  },
  watch: {
    hasFloodIndex(newval) {
      if (newval !== true) {
        this.standardLevelType = "stage";
      }
    }
  },
  async mounted() {
    //
  },
  methods: {
    getJst(utc) {
      return moment
        .utc(utc)
        .add(9, "hours")
        .format("YYYY/MM/DD HH:mm");
    }
  }
};
</script>

<style lang="scss" scoped>
.stage-level-detail {
  thead {
    background-color: #addff3;
  }
  tbody td {
    background: #eee;
  }
  tbody tr:nth-child(odd) td {
    background: #fff;
  }
  .std-color {
    width: 16px;
    height: 12px;
    border-bottom: white 3px solid;
  }
  .predict {
    color: blue;
  }
}
</style>
