import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
import { CognitoIdentityCredentials } from "aws-sdk";
import { cognitoConfig } from "../config";

function getUserPool() {
  return new CognitoUserPool({
    UserPoolId: cognitoConfig.UserPoolId,
    ClientId: cognitoConfig.ClientId
  });
}

export function isAuthenticated() {
  const userPool = getUserPool();
  const currentUser = userPool.getCurrentUser();
  return new Promise(resolve => {
    if (currentUser === null) {
      resolve(null);
    }
    currentUser.getSession((err, session) => {
      if (err) {
        resolve(null);
      } else {
        if (!session.isValid()) {
          resolve(null);
        } else {
          resolve(session);
        }
      }
    });
  });
}

export function signin(username, password, onSuccess, onFailure) {
  const userPool = getUserPool();
  const userData = { Username: username, Pool: userPool };
  const cognitoUser = new CognitoUser(userData);
  const authenticationData = {
    Username: username,
    Password: password
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: () => {
      onSuccess();
    },
    onFailure: () => {
      onFailure();
    }
  });
}

export function signout() {
  const userPool = getUserPool();
  if (userPool.getCurrentUser()) {
    userPool.getCurrentUser().signOut();
  }
}

export function getAttribute() {
  return new Promise((resolve, reject) => {
    const userPool = getUserPool();
    const currentUser = userPool.getCurrentUser();
    currentUser.getUserAttributes((err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}

export function getCurrentUserName() {
  const userPool = getUserPool();
  const currentUser = userPool.getCurrentUser();
  return currentUser.username;
}

export async function getCredentials() {
  try {
    const session = await isAuthenticated();
    if (session !== null) {
      const user = Object.assign({}, session.idToken.payload);
      if (user !== null) {
        const provider = `cognito-idp.${cognitoConfig.Region}.amazonaws.com/${cognitoConfig.UserPoolId}`;
        const token = session.idToken.jwtToken;
        return new CognitoIdentityCredentials({
          IdentityPoolId: cognitoConfig.IdentityPoolId,
          Logins: { [provider]: token }
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
  // ゲストユーザーとして認証
  console.log("gest user");
  return new CognitoIdentityCredentials({
    IdentityPoolId: cognitoConfig.IdentityPoolId
  });
}
