<template>
  <div>
    <template v-for="feature in selectedFeatures">
      <LTileLayer
        layer-type="overlay"
        v-for="(url, index) in feature.urls"
        :key="`${feature.key}-${index}`"
        :url="url"
        :options="getOptions(feature.key)"
      />
    </template>
  </div>
</template>

<script>
import { LTileLayer } from "vue2-leaflet";

export default {
  name: "TerrainFeatureLayer",
  components: { LTileLayer },
  computed: {
    selectedFeatures() {
      return this.$store.getters.selectedFeatures;
    },
    isShinsuishin() {
      const keys = this.selectedFeatures.map(f => f.key);
      return keys.includes("shinsuishin");
    }
  },
  methods: {
    getOptions(key) {
      const options = {
        opacity: 0.6
      };
      if (key === "shinsuishin") {
        Object.assign(options, {
          maxNativeZoom: 17,
          maxZoom: 18
        });
      }
      return options;
    }
  }
};
</script>
