import AWS from "aws-sdk";
import { cognitoConfig } from "../config";
import * as cognito from "./cognito";

AWS.config.region = cognitoConfig.Region;

const Bucket = "ctie.shiroishi.data";
const ConfigKey = "config/frontConfig.json";

const createS3 = async () => {
  const credentials = await cognito.getCredentials();
  return new AWS.S3({
    credentials: credentials
  });
};

export const getFrontConfig = async () => {
  const s3 = await createS3();
  const res = await s3
    .getObject({
      Bucket: Bucket,
      Key: ConfigKey
    })
    .promise();
  return JSON.parse(res.Body.toString());
};

export const putFrontConfig = async data => {
  const s3 = await createS3();
  const requestParams = {
    Bucket: Bucket,
    Key: ConfigKey,
    Body: JSON.stringify(data)
  };
  const res = await s3.putObject(requestParams).promise();
  return res.$response.httpResponse;
};

export const getTimelapseKeys = async osid => {
  const s3 = await createS3();
  const res = await s3
    .listObjectsV2({ Bucket: Bucket, Prefix: `timelapse/${osid}/` })
    .promise();
  return res.Contents.map(row => row.Key);
};

export const removeObjects = async keys => {
  const objects = keys.map(key => ({ Key: key }));
  const s3 = await createS3();
  const res = await s3
    .deleteObjects({ Bucket: Bucket, Delete: { Objects: objects } })
    .promise();
  return res;
};

export const getSignedUrl = async key => {
  const s3 = await createS3();
  const res = await s3.getSignedUrlPromise("getObject", {
    Bucket: Bucket,
    Key: key,
    Expires: 60
  });
  return res;
};
