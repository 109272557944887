<template>
  <div style="border-left: 1px solid #c1c1c1" class="pt-0 px-2">
    <div class="text-right">
      <v-btn x-small icon @click="close()">
        <v-icon x-small>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-card class="text-right caption" flat tile height="15">
      基準時刻 : {{ currentDateTime }}
    </v-card>
    <v-card class="d-flex align-center" flat tile height="30">
      <v-chip small>水位モニタリング</v-chip>
    </v-card>
    <div class="river-level-contents">
      <v-data-table
        dense
        :headers="riverLevelHeaders"
        :items="waterLevels"
        hide-default-footer
        class="river-level-table"
        :items-per-page="-1"
        @click:row="moveObservatoryDetail"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom nudge-right="30" :disabled="item.name.length < 10">
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.name }}</span>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.graph`]="{ item }">
          <div class="graph-area">
            <v-progress-linear
              v-model="item.graph.levelProportion"
              color="#3388ff"
              height="18"
            ></v-progress-linear>
            <div
              v-if="item.graph.dangerous !== null"
              class="level-line dangerous-color"
              :style="{ left: item.graph.dangerous + '%' }"
            ></div>
          </div>
        </template>
      </v-data-table>
      <div class="d-flex caption flex-row-reverse">
        (単位: m)
      </div>
    </div>
    <v-card class="d-flex align-center mt-2" tile flat height="30">
      <v-chip small>雨量モニタリング</v-chip>
    </v-card>
    <div class="rainfall-contents">
      <v-data-table
        dense
        :headers="rainfallHeaders"
        :items="rainLevels"
        hide-default-footer
        class="rainfall-table"
        :items-per-page="-1"
        @click:row="moveObservatoryDetail"
      >
      </v-data-table>
      <div class="d-flex caption flex-row-reverse">
        (現在雨量: mm/10分, 累計雨量: mm)
      </div>
    </div>
  </div>
</template>
<script>
import L from "leaflet";
import _ from "lodash";
import { OBSERVATORY } from "../enums/Type";

export default {
  name: "Monitoring",
  components: {},
  data() {
    return {
      riverLevelHeaders: [
        {
          text: "観測所",
          align: "left",
          sortable: false,
          value: "name",
          width: "100px",
          class: "data-table-header",
          cellClass: "obsname-info"
        },
        {
          text: "氾濫\n注意",
          align: "right",
          sortable: false,
          value: "warning",
          width: "40px",
          class: "data-table-header warning-color",
          cellClass: "master-level-info"
        },
        {
          text: "避難\n判断",
          align: "right",
          sortable: false,
          value: "evacuation",
          width: "40px",
          class: "data-table-header evacuation-color",
          cellClass: "master-level-info"
        },
        {
          text: "氾濫\n危険",
          align: "right",
          sortable: false,
          value: "dangerous",
          width: "40px",
          class: "data-table-header dangerous-color",
          cellClass: "master-level-info"
        },
        {
          text: "氾濫\n発生",
          align: "right",
          sortable: false,
          value: "outbreak",
          width: "40px",
          class: "data-table-header outbreak-color",
          cellClass: "master-level-info"
        },
        {
          text: "現在\n水位",
          align: "right",
          sortable: false,
          value: "level",
          width: "40px",
          class: "data-table-header",
          cellClass: "master-level-info"
        },
        {
          text: "グラフ",
          sortable: false,
          value: "graph",
          width: "60px",
          class: "data-table-header"
        }
      ],
      rainfallHeaders: [
        {
          text: "観測所",
          align: "left",
          sortable: false,
          value: "name",
          class: "data-table-header"
        },
        {
          text: "現在雨量",
          align: "right",
          sortable: false,
          value: "min10",
          width: "80px",
          class: "data-table-header"
        },
        {
          text: "累加雨量",
          align: "right",
          sortable: false,
          value: "total",
          width: "80px",
          class: "data-table-header"
        }
      ]
    };
  },
  computed: {
    waterLevels() {
      const arr = this.$store.getters.observatories;
      let observatories = arr.filter(
        obs =>
          obs.type === OBSERVATORY.STAGE.code &&
          [
            "22865_22",
            "22865_23",
            "99_41000_2_0",
            "99_41000_2_1",
            "99_41000_2_145",
            "99_41000_2_146",
            "99_41000_2_141",
            "99_41000_2_144",
            "99_41000_2_143",
            "99_41000_2_142",
            "99_41000_2_152",
            "99_41000_2_100001",
            "99_41000_2_100002"
          ].includes(obs.id)
      );
      observatories = observatories.map(obs => {
        const r = Object.assign({}, obs);
        r.standby =
          r.standbyLevel !== undefined ? r.standbyLevel.toFixed(2) : "・";
        r.warning =
          r.warningLevel !== undefined ? r.warningLevel.toFixed(2) : "・";
        r.evacuation =
          r.evacuationLevel !== undefined ? r.evacuationLevel.toFixed(2) : "・";
        r.dangerous =
          r.dangerousLevel !== undefined ? r.dangerousLevel.toFixed(2) : "・";
        r.outbreak =
          r.outbreakLevel !== undefined ? r.outbreakLevel.toFixed(2) : "・";

        if (r.level === undefined || r.level === -999) {
          if (r.kiki === true && r.chartWaterBoxLevel !== undefined) {
            r.level = "---";
          } else {
            r.level = "欠測";
          }
          r.isMissing = true;
        } else {
          r.level = r.level.toFixed(2);
          r.isMissing = false;
        }

        r.sortLevel = r.outbreakLevel || r.dangerousLevel ? 1 : 9;
        if (r.isMissing === true) {
          r.sortLevel = r.sortLevel * 10;
        }
        return r;
      });
      observatories.forEach(r => {
        let graphMaxValue = 0;
        let dangerous =
          r.dangerous !== "・"
            ? parseFloat(r.dangerous)
            : parseFloat(r.outbreak);
        let level = r.level;
        if (dangerous !== "・") {
          graphMaxValue = dangerous * 1.2;
        } else if (r.isMissing === false) {
          graphMaxValue = level + 10;
        }
        if (dangerous !== "・" && r.isMissing === false && dangerous > 12) {
          const offset = dangerous - 4;
          dangerous -= offset;
          graphMaxValue = dangerous * 1.2;
          level -= offset;
        }
        if (graphMaxValue === 0) {
          graphMaxValue = 0.1;
        }
        let levelProportion = 0;
        if (r.isMissing === false) {
          if (level > graphMaxValue) {
            levelProportion = 100;
          } else {
            levelProportion = (level / graphMaxValue) * 100;
          }
        }
        r.graph = {
          dangerous:
            dangerous !== "・" ? (dangerous / graphMaxValue) * 100 : null,
          levelProportion: levelProportion
        };
        r.riskValue =
          dangerous !== "・" && r.isMissing === false ? levelProportion : 0;
        if (r.riskValue === 0 && r.isMissing === false) {
          r.riskValue = level * 0.000001;
        }
      });
      return _.orderBy(
        observatories,
        ["sortLevel", "riskValue"],
        ["asc", "desc"]
      );
    },
    rainLevels() {
      const arr = this.$store.getters.observatories;
      let observatories = arr.filter(
        obs =>
          obs.type === OBSERVATORY.RAIN.code &&
          [
            "22865_23",
            "22865_28",
            "10497_60",
            "10497_72",
            "10497_82",
            "30000_85166"
          ].includes(obs.id)
      );
      observatories = observatories.map(obs => {
        const r = Object.assign({}, obs);
        r.sortMin10 = r.min10 === undefined ? -999 : r.min10;
        r.sortTotal = r.total === undefined ? -999 : r.total;
        if (r.min10 === -999) {
          r.min10 = "欠測";
        }
        if (r.total === -999) {
          r.total = "欠測";
        }
        return r;
      });
      return _.orderBy(
        observatories,
        ["sortMin10", "sortTotal"],
        ["desc", "desc"]
      );
    },
    leafletMap() {
      return this.$store.getters.leafletMap;
    },
    currentDateTime() {
      const baseDate = this.$store.getters.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");
      return baseDate.local().format("YYYY/MM/DD HH:mm");
    }
  },
  methods: {
    close() {
      this.$store.commit("SET_IS_MONITORING", false);
    },
    moveObservatoryDetail(info) {
      this.leafletMap.panTo(new L.LatLng(info.lat, info.lng));
      const observatory = Object.assign(info, { isMonitoring: true });
      this.$store.dispatch("FETCH_SELECTED_OBSERVATORY_DATA", observatory);
    }
  }
};
</script>

<style lang="scss">
th,
td {
  height: 24px !important;
}

.river-level-contents {
  min-height: 350px;
  max-height: 50dvh;
  overflow-y: scroll;
  .data-table-header {
    padding: 0 5px 0 3px !important;
    min-width: 60px;
    &.standby-color {
      color: #008000 !important;
    }
    &.warning-color {
      color: #ffa500 !important;
    }
    &.evacuation-color {
      color: #ff2800 !important;
    }
    &.dangerous-color {
      color: #ad02ad !important;
    }
    &.outbreak-color {
      color: #000000 !important;
    }
  }
  .master-level-info {
    font-size: 11px !important;
  }
  .obsname-info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0;
    max-width: 100px;
  }
  .theme--light.v-data-table thead tr:last-child th {
    border-bottom: 1px double black !important;
    font-weight: normal;
    text-align: center !important;
    white-space: pre-wrap;
  }
  .graph-area {
    pointer-events: none;
    position: relative;
    .level-line {
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      &.dangerous-color {
        background-color: #ad02ad !important;
        font-size: 10px;
      }
    }
  }
}
.river-level-table {
  td {
    font-size: 12px !important;
    padding: 0 5px 0 3px !important;
  }
}

.rainfall-contents {
  min-height: 100px;
  max-height: calc(100dvh - 50dvh - 140px);
  overflow-y: scroll;
  .data-table-header {
    padding: 0 5px 0 3px !important;
    min-width: 60px;
  }
  .theme--light.v-data-table thead tr:last-child th {
    border-bottom: 1px double black !important;
  }
  .data-table-header:nth-child(2) {
    background-color: #3a1e87 !important;
    color: white !important;
  }
  .data-table-header:nth-child(3) {
    background-color: #3a1e87 !important;
    color: white !important;
  }
  .theme--light.v-data-table thead tr th {
    color: black;
  }
}

.rainfall-table {
  td {
    padding: 0 5px 0 3px !important;
    font-size: 12px !important;
    line-height: 1;
    height: inherit;
  }
  tr td:nth-child(2) {
    border-right: 1px solid #3a1e87;
    border-left: 1px solid #3a1e87;
  }
  tr td:nth-child(3) {
    border-right: 1px solid #3a1e87;
  }
}
</style>

<style lang="scss">
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px;
}
</style>
