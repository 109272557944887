<template>
  <LLayerGroup v-if="rows.length > 0">
    <div v-for="row in rows" :key="row.s3_key">
      <LMarker :pane="pane" :lat-lng="[row.lat, row.lng]" @click="preview(row)">
        <LIcon :icon-size="[110, 110]" :icon-anchor="[55, 120]">
          <div class="photo-container">
            <img :src="row.thumbUrl ? row.thumbUrl : row.url" />
          </div>
        </LIcon>
      </LMarker>
      <LMarker :pane="pane" :lat-lng="[row.lat, row.lng]" @click="remove(row)">
        <LIcon :icon-anchor="[-30, 130]">
          <v-btn x-small fab><v-icon>mdi-close</v-icon></v-btn>
        </LIcon>
      </LMarker>
      <LCircleMarker
        :lat-lng="[row.lat, row.lng]"
        :radius="10"
        :weight="1"
        :fill="true"
        fillColor="grey"
        color="grey"
      />
    </div>
  </LLayerGroup>
</template>

<script>
import { LIcon, LMarker, LCircleMarker, LLayerGroup } from "vue2-leaflet";
import { MapPane } from "../../enums/Type";
export default {
  name: "ChatThumbnailLayer",
  components: {
    LLayerGroup,
    LIcon,
    LMarker,
    LCircleMarker
  },

  data() {
    return {
      pane: MapPane.ChatThumbnailLayer.name
    };
  },

  computed: {
    rows() {
      return this.$store.getters.chatThumbnails;
    }
  },

  watch: {
    rows() {
      //
    }
  },

  methods: {
    remove(target) {
      const rows = this.rows.filter(row => row.id !== target.id);
      this.$store.commit("SET_CHAT_THUMBNAILS", rows);
    },
    preview(target) {
      if (!target.url) return;
      window.open(target.url);
    }
  }
};
</script>
<style lang="scss" scoped>
.photo-container {
  background-color: #fff;
  padding: 5px;
  height: 110px;
  widows: 100px;
  position: relative;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  img {
    width: 100px !important;
    height: 100px !important;
    object-fit: cover;
  }
}
.photo-container:after {
  border-right: solid 10px transparent;
  border-left: solid 10px transparent;
  border-top: solid 10px #fff;
  transform: translateX(-50%);
  position: absolute;
  z-index: 1;
  content: "";
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
}
</style>
