import axios from "axios";

export const get = async (url, params) => {
  return axios
    .create({
      responseType: "json",
      baseURL: "https://data.riskma.net/bosai"
      // baseURL: "https://sl3mgfgby8.execute-api.ap-northeast-1.amazonaws.com/dev/bosai"
      // baseURL: "http://localhost:8000/bosai"
    })
    .get(url, {
      params
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.error(err);
      return null;
    });
};

export const getOutsideApi = async (url, params) => {
  return axios
    .create({
      responseType: "json"
    })
    .get(url, {
      params
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.error(err);
      return null;
    });
};
