<template>
  <div v-if="url">
    <template v-for="(img, index) in slicedImages">
      <LMarker
        :key="index"
        :lat-lng="[img.markerLat, img.markerLng]"
        v-if="img.isInDeviceViewPort"
      >
        <LIcon :icon-anchor="[0, 0]">
          <div
            :style="{
              width: img.divWidth + 'px',
              height: img.divHeight + 'px',
              overflow: 'hidden',
              'pointer-events': 'none',
              borderBottom: bordersColor ? `1px solid ${bordersColor}` : 'none'
            }"
          >
            <img
              class="riskImage"
              alt="rainfallImage"
              :src="url"
              :style="{
                transform: `translate(0,${img.translateY}px)`,
                width: img.imgWidth + 'px',
                height: img.imgHeight + 'px',
                'pointer-events': 'none',
                opacity: opacity
              }"
            />
          </div>
        </LIcon>
      </LMarker>
    </template>
  </div>
</template>
<script>
import { LMarker, LIcon } from "vue2-leaflet";

export default {
  name: "RainWideLayer",
  components: {
    LMarker,
    LIcon
  },
  data() {
    return {
      viewportBottomLat: 0,
      viewportTopLat: 0,
      bordersColor: null
    };
  },
  props: {
    boundsList: {
      type: [Array, Object],
      required: true
    }
  },
  watch: {
    async rains() {
      this.updateViewportLatitude();
    }
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    opacity() {
      return this.$store.getters.riskSubControl.opacity;
    },
    rains() {
      return this.$store.getters.rainImageInfo;
    },
    selectedRain() {
      const d = this.$store.getters.selectedDate.clone().utc();
      const date = d.format("YYYY/MM/DD HH:mm");
      return this.rains.find(r => r.date === date);
    },
    url() {
      return this.selectedRain ? this.selectedRain.url : "";
    },
    selectedRainType() {
      return this.selectedRain ? this.selectedRain.type : "";
    },
    bounds() {
      if (this.selectedRainType === "") {
        return [];
      }
      return this.boundsList[this.selectedRainType];
    },
    slicedImages() {
      if (!this.map || !this.bounds || this.bounds.length === 0) {
        return [];
      }
      if (!this.url) {
        return [];
      }
      const imagesInfo = [];
      const coordinate = {
        north: this.bounds[0][0],
        west: this.bounds[0][1],
        south: this.bounds[1][0],
        east: this.bounds[1][1]
      };
      const sliceCount = 50;
      const slicedLatHeight =
        (coordinate.north - coordinate.south) / sliceCount;
      for (let i = 0; i < sliceCount; i++) {
        const slicedImageBottomLeftPoint = this.map.project([
          coordinate.north - i * slicedLatHeight - slicedLatHeight,
          coordinate.west
        ]);
        const slicedImageTopRightPoint = this.map.project([
          coordinate.north - i * slicedLatHeight,
          coordinate.east
        ]);
        let slicedImageHeight =
          slicedImageBottomLeftPoint.y - slicedImageTopRightPoint.y;
        let slicedImageWidth =
          slicedImageTopRightPoint.x - slicedImageBottomLeftPoint.x;
        if (
          Math.round(slicedImageBottomLeftPoint.y) <
          Math.round(
            slicedImageBottomLeftPoint.y + Math.ceil(slicedImageHeight)
          )
        ) {
          slicedImageHeight -= -1;
        }
        const markerLat = coordinate.north - i * slicedLatHeight;
        const markerLng = coordinate.west;
        const divHeight = Math.ceil(slicedImageHeight) - 1.4;
        imagesInfo.push({
          markerLat: markerLat,
          markerLng: markerLng,
          divWidth: slicedImageWidth,
          divHeight: divHeight,
          imgWidth: slicedImageWidth,
          imgHeight: slicedImageHeight * sliceCount,
          translateY: -(i * slicedImageHeight + 1),
          isInDeviceViewPort: this.isElementInDeviceViewPort(
            markerLat,
            markerLng,
            divHeight
          )
        });
      }
      return imagesInfo;
    }
  },
  created() {
    this.updateViewportLatitude();
  },
  mounted() {
    if (this.map) {
      this.map.on("moveend", this.updateViewportLatitude);
    }
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off("moveend", this.updateViewportLatitude);
    }
  },
  methods: {
    updateViewportLatitude() {
      this.viewportBottomLat = this.getScreenBottomLat();
      this.viewportTopLat = this.getScreenTopLat();
    },
    getScreenBottomLat() {
      let centerLatLng = this.map.getCenter(); // get map center
      let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
      let pointMinY = [pointC.x, pointC.y - window.innerHeight / 2];
      let latlng = this.map.containerPointToLatLng(pointMinY);
      return latlng.lat;
    },
    getScreenTopLat() {
      let centerLatLng = this.map.getCenter(); // get map center
      let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
      let pointMaxY = [pointC.x, pointC.y + window.innerHeight / 2];
      let latlng = this.map.containerPointToLatLng(pointMaxY);
      return latlng.lat;
    },
    isElementInDeviceViewPort(
      elementBottomLat,
      elementBottomLng,
      elementHeightPx
    ) {
      const elementTopLat = this.getElemenTopLat(
        [elementBottomLat, elementBottomLng],
        elementHeightPx
      );

      if (
        elementTopLat < this.viewportBottomLat &&
        elementTopLat > this.viewportTopLat
      ) {
        return true;
      }
      if (
        elementBottomLat < this.viewportBottomLat &&
        elementBottomLat > this.viewportTopLat
      ) {
        return true;
      }
      if (
        elementBottomLat >= this.viewportBottomLat &&
        elementTopLat <= this.viewportTopLat
      ) {
        return true;
      }

      return false;
    },
    getElemenTopLat(elementTopLatLng, elementHeightPx) {
      let pointDivTop = this.map.latLngToContainerPoint(elementTopLatLng); // convert to containerpoint (pixels)
      let pointY = [pointDivTop.x, pointDivTop.y + elementHeightPx];

      let latlng = this.map.containerPointToLatLng(pointY);
      return latlng.lat;
    }
  }
};
</script>
