<template>
  <div class="dam-level-detail pa-3">
    <div class="mb-3">
      <div class="caption text--secondary">
        {{ selectedObservatory.address }}
      </div>
      <div class="caption text--secondary">
        {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
      </div>
      <div
        class="caption text--secondary text-right"
        v-if="selectedObservatory.source"
      >
        {{ `データ提供: ${selectedObservatory.source}` }}
      </div>
    </div>
    <div class="mt-5 caption" v-if="isDeficit">
      この観測所は欠測しています。
    </div>
    <DamChart
      v-else
      class="d-flex"
      :height="250"
      :selected-observatory="selectedObservatory"
    />
    <v-simple-table dense class="mt-3" v-if="!isDeficit">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">日時</th>
            <th class="text-left">貯水位</th>
            <th class="text-left">全流入量</th>
            <th class="text-left">全放流量</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ts in timeSeries"
            :key="ts.time"
            :class="{ predict: ts.predict }"
          >
            <td>{{ ts.time }}</td>
            <td>{{ ts.level }}</td>
            <td>{{ ts.qinAll }}</td>
            <td>{{ ts.qoutAll }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import DamChart from "./DamChart";
import moment from "moment";

export default {
  name: "DamDetail",
  components: { DamChart },
  computed: {
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    reals() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      }
      return this.selectedObservatory.timeSeries.filter(v => v.type === "real");
    },
    forecasts() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      }
      return this.selectedObservatory.timeSeries.filter(
        v => v.type === "forecast"
      );
    },
    isDeficit() {
      return this.reals.length === 0 && this.forecasts.length === 0;
    },
    timeSeries() {
      const timeSeries = [];
      this.reals.forEach(r => {
        r["predict"] = false;
        timeSeries.push(r);
      });
      this.forecasts.forEach(r => {
        r["predict"] = true;
        timeSeries.push(r);
      });
      return timeSeries.map(r => {
        const time = moment
          .utc(r.date, "YYYY/MM/DD HH:mm")
          .local()
          .format("MM/DD HH:mm");
        return {
          time: time,
          level: r.level !== undefined ? parseFloat(r.level).toFixed(2) : "---",
          qinAll:
            r.qinAll !== undefined ? parseFloat(r.qinAll).toFixed(2) : "---",
          qoutAll:
            r.qoutAll !== undefined ? parseFloat(r.qoutAll).toFixed(2) : "---",
          predict: r.predict
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dam-level-detail {
  thead th {
    background-color: #addff3;
    font-size: 0.6em !important;
    font-weight: normal;
    white-space: nowrap;
  }
  tbody td {
    background: #eee;
    font-size: 0.8em !important;
    white-space: nowrap;
  }
  tbody tr:nth-child(odd) td {
    background: #fff;
  }
  .predict {
    color: blue;
  }
}
</style>
