<template>
  <v-card class="fill-height d-flex admin-page" flat tile>
    <LMap
      ref="adminMap"
      :min-zoom="13"
      :max-zoom="16"
      :zoom="14"
      :center="center"
      :options="{ zoomControl: false, scrollWheelZoom: true }"
      class="fill-height"
    >
      <LTileLayer :url="baseTileUrl" />
      <div v-for="ca in cameras" :key="ca.id">
        <LMarker :lat-lng="[ca.lat, ca.lng]" @click="selectMapCamera(ca)">
          <LIcon :options="{ html: cameraIconHtml }" :icon-anchor="[14, 14]" />
          <LTooltip :options="tooltipOption">
            {{ ca.name }}
          </LTooltip>
        </LMarker>
      </div>
    </LMap>
    <v-card tile flat class="cameras-container" min-width="450">
      <v-card tile flat class="px-2 d-flex align-center" height="55">
        <div class="caption mr-2">ステータス</div>
        <v-btn-toggle dense v-model="status">
          <v-btn value="normal" :class="getStatusClass('normal')">
            平常
          </v-btn>
          <v-btn value="warning" :class="getStatusClass('warning')">
            災害
          </v-btn>
        </v-btn-toggle>
        <v-btn small class="ml-auto" color="secondary" @click="save()">
          保存
        </v-btn>
      </v-card>

      <v-tabs v-model="tab" class="mx-2 my-2" height="30">
        <v-tab>カメラ一覧</v-tab>
        <v-tab>バッテリー</v-tab>
      </v-tabs>

      <v-simple-table dense fixed-header height="calc(100dvh - 110px)">
        <!-- カメラ一覧 -->
        <template v-slot:default v-if="tab === 0">
          <thead>
            <tr>
              <th class="text-left">名称</th>
              <th class="text-left">データ提供元</th>
              <th class="text-center">平常時</th>
              <th class="text-center">災害時</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ca in cameras"
              :key="ca.id"
              :id="`row-${ca.id}`"
              @click="selectListCamera(ca)"
            >
              <td class="camera-caption" :class="getSelectedClass(ca)">
                {{ ca.name }}
              </td>
              <td class="camera-caption">{{ ca.source }}</td>
              <td><v-checkbox hide-details dense v-model="ca.normal" /></td>
              <td><v-checkbox hide-details dense v-model="ca.warning" /></td>
            </tr>
          </tbody>
        </template>

        <!-- バッテリー確認 -->
        <template v-slot:default v-else-if="tab === 1">
          <thead>
            <tr>
              <th class="text-left">名称</th>
              <th class="text-left">バッテリー</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ca in cameras"
              :key="ca.id"
              :id="`row-${ca.id}`"
              style="height: 40px"
              @click="selectListCamera(ca)"
            >
              <td :class="getSelectedClass(ca)">{{ ca.name }}</td>
              <td>{{ ca.battery }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip, LIcon } from "vue2-leaflet";
import L from "leaflet";
import { BRANCH_OFFICE } from "../enums/BranchOffice";
import { OBSERVATORY } from "../enums/Type";
import * as axiosHelper from "../utils/axiosHelper";
import * as cognito from "../utils/cognito";
import * as s3 from "../utils/s3";
import { getDynamoDBClient } from "../utils/dynamodb";
import moment from "moment";

const batteryCamerasLink = {
  ax_shiroishi_1: 14,
  ax_shiroishi_2: 15,
  ax_shiroishi_3: 1,
  ax_shiroishi_4: 21,
  ax_shiroishi_5: 5,
  ax_shiroishi_6: 6,
  ax_shiroishi_7: 7,
  ax_shiroishi_8: 20,
  ax_shiroishi_9: 9,
  ax_shiroishi_10: 19
};

export default {
  name: "Admin",

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon
  },

  data() {
    return {
      baseTileUrl: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
      center: new L.LatLng(BRANCH_OFFICE[0].lat, BRANCH_OFFICE[0].lng),
      cameras: [],
      baseCameras: [],
      tab: 0,
      status: "",
      snackbar: false,
      snackbarText: "",
      cameraIconHtml: `<div class="v-icon notranslate mdi mdi-camera theme--light" style="color: #B10048; font-size: 28px;" />`,
      selectedId: "",
      map: null,
      tooltipOption: {
        direction: "top",
        offset: [0, -14]
      },
      tooltip: null,
      loadedBatteryStatus: false
    };
  },

  computed: {
    //
  },

  watch: {
    tab() {
      if (this.tab === 0) {
        this.cameras = this.baseCameras;
      } else {
        this.cameras = this.baseCameras.filter(ca =>
          ca.id.startsWith("ax_shiroishi_")
        );
      }
      this.selectedId = "";
      this.tooltip.remove();
    }
  },

  async created() {
    const session = await cognito.isAuthenticated();
    if (!session) {
      this.$router.replace("/login");
      return;
    }
    this.map = this.$refs.adminMap.mapObject;
    await this.fetchCameras();
    try {
      await this.fetchBatteryStatus();
    } catch (e) {
      console.error(e);
    }

    this.tooltip = L.tooltip(this.tooltipOption);
  },

  methods: {
    getStatusClass(status) {
      return { "font-weight-bold": this.status === status };
    },

    async fetchCameras() {
      // front config
      const frontConfig = await s3.getFrontConfig();
      this.status = frontConfig.status;
      const normals = frontConfig.normals;
      const warnings = frontConfig.warnings;

      // observatories
      const res = await axiosHelper.get("/observatories", {
        domain: process.env.VUE_APP_DOMAIN
      });
      if (res === null || !res.observatories) {
        this.cameras = [];
        return;
      }
      const cameras = res.observatories.filter(
        obs => obs.type === OBSERVATORY.CAMERA.code
      );
      cameras.forEach(camera => {
        camera.name = camera.name.replace("（", "\n(");
        camera.name = camera.name.replace("）", ")");
        if (camera.source) {
          let source = camera.source.replace("　", "\n");
          source = source.replace("ライブカメラ", "\nライブカメラ");
          camera.source = source;
        }
        camera.normal = normals.includes(camera.id);
        camera.warning = warnings.includes(camera.id);
      });
      this.baseCameras = cameras;
      this.cameras = cameras;
      this.map.invalidateSize();
    },

    async fetchBatteryStatus() {
      if (this.loadedBatteryStatus) return;
      console.log("fetchBatteryStatus");
      const dynamodb = await getDynamoDBClient();
      const sampleTime = moment()
        .utc()
        .subtract(30, "minutes")
        .valueOf();
      const processes = Object.entries(batteryCamerasLink).map(
        async ([osid, deviceId]) => {
          const params = {
            TableName: "Shiroishi_obs",
            KeyConditionExpression:
              "device_id = :device_id AND sample_time >= :sample_time",
            ExpressionAttributeValues: {
              ":device_id": { N: String(deviceId) },
              ":sample_time": { N: String(sampleTime) }
            },
            ScanIndexForward: false,
            Limit: 1
          };
          const res = await dynamodb.query(params).promise();
          if (!res.Items || res.Items.length === 0) {
            return {
              osid: osid,
              battery: "未受信"
            };
          }
          return {
            osid: osid,
            battery: res.Items[0].device_data.M.VPer.N + "%"
          };
        }
      );
      const res = await Promise.all(processes);
      res.forEach(r => {
        const camera = this.baseCameras.find(ca => ca.id === r.osid);
        if (camera) camera.battery = r.battery;
      });
      this.loadedBatteryStatus = true;
    },

    displayTooltip(target) {
      this.tooltip.remove();
      this.tooltip.setLatLng([target.lat, target.lng]);
      this.tooltip.setContent(target.name);
      this.tooltip.addTo(this.map);
    },

    selectListCamera(target) {
      this.selectedId = target.id;
      if (!this.map) return;
      if (target.lat && target.lng) {
        this.map.setView([target.lat, target.lng], 15);
        this.displayTooltip(target);
      }
    },

    selectMapCamera(target) {
      this.selectedId = target.id;
      this.displayTooltip(target);

      // リストを見える位置にスクロール
      const targetIndex = this.cameras.findIndex(ca => ca.id === target.id);
      if (targetIndex < 0) return;
      const id = `#row-${target.id}`;
      const height = this.$el.querySelector(id).clientHeight;
      const tableWrapper = this.$el.getElementsByClassName(
        "v-data-table__wrapper"
      )[0];
      tableWrapper.scroll({ top: height * targetIndex, behavior: "smooth" });
    },

    getSelectedClass(row) {
      return row.id === this.selectedId ? "selected" : "unselected";
    },

    async save() {
      const data = {
        status: this.status,
        normals: this.cameras.filter(ca => ca.normal).map(ca => ca.id),
        warnings: this.cameras.filter(ca => ca.warning).map(ca => ca.id)
      };
      const res = await s3.putFrontConfig(data);
      if (res.statusCode === 200) {
        this.snackbarText = "保存しました";
        this.snackbar = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.admin-page {
  width: calc(100vw - 10px);
  .cameras-container {
    border-left: 1px solid rgb(202, 202, 202) !important;
  }
  th {
    padding: 2px 5px !important;
  }
  td {
    font-size: 12px !important;
    padding: 2px 5px 2px 8px !important;
    white-space: pre-wrap !important;
    .v-input--selection-controls {
      margin: 0 !important;
    }
    .v-input--selection-controls__ripple {
      margin: 0 !important;
    }
  }
  td:first-child {
    width: 200px;
  }
  td:nth-child(2) {
    width: 150px;
  }
  td:nth-child(3),
  td:nth-child(4) {
    width: 50px !important;
    padding-left: 12px !important;
  }
  .selected {
    border-left: 4px solid rgb(255, 121, 121);
  }
  .unselected {
    border-left: 4px solid white;
  }
}
</style>
