const OBSERVATORY = {
  RAIN: {
    code: 1,
    name: "雨量"
  },
  STAGE: {
    code: 4,
    name: "水位"
  },
  DAM: {
    code: 7,
    name: "ダム"
  },
  TIDE: {
    code: 12,
    name: "潮位"
  },
  FLOODSENSOR: {
    code: 20,
    name: "浸水情報"
  },
  CAMERA: {
    code: 30,
    name: "カメラ"
  },
  LIVECAMERA: {
    code: 31,
    name: "ｶﾒﾗ(動画)"
  }
};

const STAGES = {
  OUTBREAK: {
    label: "氾濫発生水位",
    color: "#000000"
  },
  DANGEROUS: {
    label: "氾濫危険水位",
    color: "#AD02AD"
  },
  EVACUATION: {
    label: "避難判断水位",
    color: "#FF2800"
  },
  WARNING: {
    label: "氾濫注意水位",
    color: "#FFA500"
  },
  STANDBY: {
    label: "水防団待機水位",
    color: "#008000"
  }
};

const takeoSinsuiBaseUrl =
  "https://simachi:bousai@takeo-shinsui.jp/bousai/city";

const FloodSensorTakeoSinsui = [
  {
    id: "99_41000_7_1",
    url: `${takeoSinsuiBaseUrl}/AdhocGraph.html?place=1&suikei=1`
  },
  {
    id: "99_41000_7_2",
    url: `${takeoSinsuiBaseUrl}/SensorGraph?place=5`
  },
  {
    id: "99_41000_7_3",
    url: `${takeoSinsuiBaseUrl}/SensorGraph?place=6`
  },
  {
    id: "99_41000_7_4",
    url: `${takeoSinsuiBaseUrl}/SensorGraph?place=7`
  },
  {
    id: "99_41000_7_5",
    url: `${takeoSinsuiBaseUrl}/SensorGraph?place=22`
  }
];

const MapPane = {
  ObservatoryMarkersLayer: {
    name: "observatory-markers-layer",
    zIndex: 600
  },
  ObservatorySendorMarkersLayer: {
    name: "observatory-sensor-markers-layer",
    zIndex: 600
  },
  BranchOfficeMarker: {
    name: "branch-office-marker",
    zIndex: 610
  },
  ChatThumbnailLayer: {
    name: "chat-thumbnail-layer",
    zIndex: 620
  }
};

export { OBSERVATORY, STAGES, FloodSensorTakeoSinsui, MapPane };
