<template>
  <l-map
    ref="leafletMap"
    :min-zoom="minZoom"
    :max-zoom="maxZoom"
    :zoom="zoom"
    :center="centerPosition"
    :options="options"
    :zoom-animation="false"
  >
    <base-tile-layer />
    <mobile-hazard-map-layer />
    <l-marker v-if="currentCoordinate != null" :lat-lng="currentCoordinate">
      <l-icon
        :icon-size="[20, 40]"
        :icon-anchor="[10, 40]"
        :icon-url="require('../../assets/images/png/main-contents-pin.png')"
      />
    </l-marker>
    <l-marker :lat-lng="[33.18094, 130.14344]">
      <l-icon
        :icon-size="[36, 36]"
        :icon-anchor="[18, 36]"
        :icon-url="require('../../assets/images/png/domain.png')"
      />
    </l-marker>
    <CityBorderLayer />

    <l-tile-layer
      layer-type="overlay"
      url="https://s3.ap-northeast-1.amazonaws.com/ctie.hachioji.web/static/tile/river/{z}/{x}/{y}.png"
      v-if="false"
    ></l-tile-layer>

    <ObservatorySensorLayer v-if="isMobileObservatoryRisk" />
    <observatory-layer v-if="isMobileObservatoryRisk" />
    <template v-if="selectedRisk.key === Risk.RAIN.key">
      <rain-layer />
    </template>
    <template v-if="selectedRisk.key === Risk.RIVER_FLOODING.key">
      <river-flooding-layer />
    </template>
    <template v-if="selectedRisk.key === Risk.DOSYA.key">
      <dosya-layer />
    </template>
    <template v-if="selectedRisk.key === Risk.KOUZUI.key">
      <KouzuiLayer />
    </template>

    <l-control position="topleft">
      <div class="d-flex mb-2">
        <v-btn
          small
          color="secondary"
          class="mr-1"
          style="pointer-events: auto"
          @click="resetCenterPosition()"
        >
          <v-icon>mdi-home-map-marker</v-icon>
        </v-btn>
        <v-btn
          small
          color="secondary"
          class="mr-1"
          style="pointer-events: auto"
          @click="getCurrentPosition()"
        >
          <v-icon>mdi-map-marker-radius</v-icon>
        </v-btn>
        <v-btn
          small
          color="secondary"
          style="pointer-events: auto"
          @click="showMobileCameraMonitor()"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </div>
      <div
        class="my-1 d-flex"
        v-if="isMobileObservatoryRisk === true || selectedRisk.key"
      >
        <v-btn
          small
          class="px-2 mr-1"
          color="secondary"
          @click="showObservatoryPanel = !showObservatoryPanel"
          style="pointer-events: painted;"
        >
          <div class="mr-2">表示</div>
          <v-icon v-if="showObservatoryPanel">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          v-if="isPrivateMode"
          small
          color="secondary"
          class="disp-btn mr-1"
          style="pointer-events: auto"
          @click="showMobileChat()"
        >
          <div>チャット</div>
        </v-btn>
        <v-btn
          v-if="mobileHazardMap.key"
          small
          color="secondary"
          class="disp-btn mr-1"
          style="pointer-events: auto"
          @click="switchMobileHazardMap(!isShowMobileHazardMap)"
        >
          <div class="mr-1">凡例</div>
          <v-icon v-if="isShowMobileHazardMap">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </div>
      <div v-if="showObservatoryPanel">
        <div style="width: 140px">
          <template
            v-if="
              selectedRisk.key === Risk.RAIN.key ||
                selectedRisk.key === Risk.RIVER_FLOODING.key ||
                selectedRisk.key === Risk.DOSYA.key ||
                selectedRisk.key === Risk.SNOW.key
            "
          >
            <v-card class="pa-2 mb-1">
              <LayerOpacityControl />
            </v-card>
          </template>
          <ObservatoryControlLayer v-if="isMobileObservatoryRisk" />
        </div>
      </div>
      <mobile-hazard-map-control />
    </l-control>
    <l-control position="topright">
      <mobile-basetime-control />
    </l-control>
    <l-control position="bottomleft">
      <zoom-control class="mb-2" />
      <slot name="bottomleft"></slot>
    </l-control>
    <l-control position="bottomright">
      <div v-if="showUsageGuidePanel">
        <observatory-usage-guide />
        <mobile-usage-guide-layer />
      </div>
      <div class="text-right mt-1">
        <v-btn
          small
          class="px-2"
          color="secondary"
          @click="showUsageGuidePanel = !showUsageGuidePanel"
          style="pointer-events: painted;"
        >
          <div class="mr-1">凡例</div>
          <v-icon v-if="!showUsageGuidePanel">mdi-chevron-down</v-icon>
          <v-icon v-if="showUsageGuidePanel">mdi-chevron-up</v-icon>
        </v-btn>
      </div>
    </l-control>
  </l-map>
</template>

<script>
import { LMap, LControl, LIcon, LMarker, LTileLayer } from "vue2-leaflet";
import L from "leaflet";

import BaseTileLayer from "./layers/BaseTileLayer";
import { Risk } from "../../enums/Risk";
import MobileHazardMapLayer from "./mobileLayers/MobileHazardMapLayer";
import MobileHazardMapControl from "./mobileLayers/MobileHazardMapControl";
import ObservatoryLayer from "./layers/ObservatoryLayer";
import ObservatorySensorLayer from "./layers/ObservatorySensorLayer";
import RainLayer from "./layers/RainLayer";
import RiverFloodingLayer from "./layers/RiverFloodingLayer";
import MobileBasetimeControl from "./mobileLayers/MobileBasetimeControl";
import ZoomControl from "./layers/ZoomControl";
import LayerOpacityControl from "./layers/LayerOpacityControl";
import ObservatoryControlLayer from "./layers/ObservatoryControlLayer";
import MobileUsageGuideLayer from "./mobileLayers/MobileUsageGuideLayer";
import { BRANCH_OFFICE } from "../../enums/BranchOffice";
import ObservatoryUsageGuide from "./layers/ObservatoryUsageGuide";
import DosyaLayer from "./layers/DosyaLayer";
import KouzuiLayer from "./layers/KouzuiLayer";
import { MapPane } from "../../enums/Type";
import CityBorderLayer from "./layers/CityBorderLayer";
import { isPrivateMode } from "../../utils/common";

export default {
  name: "MobileBaseMap",
  components: {
    MobileUsageGuideLayer,
    ObservatoryControlLayer,
    LayerOpacityControl,
    ZoomControl,
    MobileBasetimeControl,
    RiverFloodingLayer,
    RainLayer,
    ObservatoryLayer,
    ObservatorySensorLayer,
    MobileHazardMapControl,
    MobileHazardMapLayer,
    LMap,
    BaseTileLayer,
    LControl,
    LIcon,
    LMarker,
    DosyaLayer,
    KouzuiLayer,
    ObservatoryUsageGuide,
    CityBorderLayer,
    LTileLayer
  },
  data() {
    return {
      isPrivateMode,
      Risk,
      minZoom: 6,
      maxZoom: 17,
      options: { zoomControl: false },
      zoom: 14,
      centerPosition: new L.LatLng(BRANCH_OFFICE[0].lat, BRANCH_OFFICE[0].lng),
      currentCoordinate: null,
      showUsageGuidePanel: false,
      showObservatoryPanel: false,
      cameraMonitorWindow: null,
      chatWindow: null
    };
  },
  computed: {
    isShowMobileHazardMap() {
      return this.$store.getters.isShowMobileHazardMap;
    },
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    mobileHazardMap() {
      return this.$store.getters.mobileHazardMap;
    },
    isMobileObservatoryRisk() {
      return this.$store.getters.isMobileObservatoryRisk;
    }
  },
  created() {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  },
  mounted() {
    const map = this.$refs.leafletMap.mapObject;
    this.$store.commit("SET_LEAFLET_MAP", map);
    if (map) {
      Object.values(MapPane).forEach(pane => {
        if (map.getPane(pane.name) === undefined) {
          map.createPane(pane.name).style.zIndex = pane.zIndex;
        }
      });
    }
  },
  methods: {
    resetCenterPosition() {
      this.$refs.leafletMap.mapObject.panTo(this.centerPosition);
    },
    getCurrentPosition() {
      if (!navigator.geolocation) {
        alert(
          "現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。"
        );
        return;
      }

      const options = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0
      };

      navigator.geolocation.getCurrentPosition(
        this.success,
        this.error,
        options
      );
    },
    success(position) {
      this.currentCoordinate = [
        position.coords.latitude,
        position.coords.longitude
      ];
      this.$refs.leafletMap.mapObject.panTo(
        new L.LatLng(position.coords.latitude, position.coords.longitude)
      );
    },

    error(error) {
      switch (error.code) {
        case 1: //PERMISSION_DENIED
          alert("位置情報の利用が許可されていません");
          break;
        case 2: //POSITION_UNAVAILABLE
          alert("現在位置が取得できませんでした");
          break;
        case 3: //TIMEOUT
          alert("タイムアウトになりました");
          break;
        default:
          alert("現在位置が取得できませんでした");
          break;
      }
    },
    displayMenu() {
      this.$store.commit("SET_IS_SHOW_MOBILE_MENU", true);
    },
    switchMobileHazardMap(isShow) {
      this.$store.commit("SET_IS_SHOW_MOBILE_HAZARD_MAP", isShow);
    },
    showMobileCameraMonitor() {
      const url = "/#/mobile-camera-monitor";
      if (
        this.cameraMonitorWindow === null ||
        this.cameraMonitorWindow.closed === true
      ) {
        this.cameraMonitorWindow = window.open(url);
      } else {
        this.cameraMonitorWindow.location.href = url;
        setTimeout(() => {
          this.cameraMonitorWindow.focus();
        }, 500);
      }
    },
    showMobileChat() {
      const url = "/#/mobile-chat";
      if (this.chatWindow === null || this.chatWindow.closed === true) {
        this.chatWindow = window.open(url);
      } else {
        this.chatWindow.location.href = url;
        setTimeout(() => {
          this.chatWindow.focus();
        }, 500);
      }
    }
  }
};
</script>

<style lang="scss">
.leaflet-control-container {
  pointer-events: none;
}
.leaflet-top.leaflet-left {
  pointer-events: none !important;
  width: 120px;

  .leaflet-control {
    width: 100%;
    pointer-events: none;
  }
}
.leaflet-top.leaflet-right {
  z-index: 4000;
}

.leaflet-bottom.leaflet-right {
  pointer-events: none !important;
  .leaflet-control {
    pointer-events: none;
  }
}

.leaflet-bottom.leaflet-left {
  pointer-events: none !important;
  .leaflet-control {
    pointer-events: none !important;
  }
}

.controls {
  position: absolute;
  > * {
    position: relative;
    clear: both;
  }
  z-index: 2000;
}
.center {
  left: 50%;
  transform: translateX(-50%);
  > * {
    float: left;
  }
}

.top {
  top: 20px;
  > * {
    margin: 0 10px 10px 10px;
  }
}

.bottom {
  bottom: 50px !important;
  > * {
    margin: 0 10px 10px 10px;
  }
}
</style>
