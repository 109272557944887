<template>
  <div class="rain-level-detail pa-3">
    <div class="caption text--secondary">
      {{ selectedObservatory.address }}
    </div>
    <div class="caption text--secondary">
      {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
    </div>
    <div
      class="caption text--secondary text-right"
      v-if="selectedObservatory.source"
    >
      {{ `データ提供: ${selectedObservatory.source}` }}
    </div>
    <div class="d-flex align-center mt-2">
      <v-card
        width="175"
        class="caption text--secondary mr-3"
        flat
        v-if="predictData.length > 0"
      >
        予測期間
      </v-card>
      <v-card width="120" class="caption text--secondary" flat>
        時間表示
      </v-card>
    </div>
    <v-card class="d-flex align-center" flat tile>
      <v-btn-toggle
        v-if="predictData.length > 0"
        v-model="displayHours"
        mandatory
        dense
        class="mr-3"
      >
        <v-btn small :value="6">
          6時間
        </v-btn>
        <v-btn small :value="36">
          36時間
        </v-btn>
        <v-btn small :value="84" @click="displayInterval = 60" v-if="false">
          84時間
        </v-btn>
      </v-btn-toggle>
      <v-btn-toggle v-model="displayInterval" mandatory dense>
        <v-btn small :value="10">
          10分毎
        </v-btn>
        <v-btn small :value="60">
          時間毎
        </v-btn>
      </v-btn-toggle>
    </v-card>
    <div class="d-flex my-2" v-if="!isDeficit">
      <div class="caption">(mm/{{ displayInterval }}分)</div>
      <div class="caption ml-auto">(mm)</div>
    </div>
    <RainChart
      v-if="!isDeficit"
      :height="250"
      :selected-observatory="selectedObservatory"
      :displayHours="displayHours"
      :displayInterval="displayInterval"
      :currentDate="currentDate"
    />
    <v-simple-table dense class="mt-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">日時</th>
            <th class="text-left" v-if="displayInterval === 10">10分</th>
            <th class="text-left" v-if="displayInterval === 60">60分</th>
            <th class="text-left">累加</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(ts, i) in timeSeries"
            :key="i"
            :class="{ predict: ts.predict }"
          >
            <td>{{ ts.time }}</td>
            <td v-if="displayInterval === 10">{{ ts.min10 }}</td>
            <td v-if="displayInterval === 60">{{ ts.min60 }}</td>
            <td>{{ ts.total }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="mt-5 caption" v-if="isDeficit">
      この観測所は欠測しています。
    </div>
  </div>
</template>

<script>
import RainChart from "./RainChart";
import moment from "moment";

export default {
  name: "RainDetail",
  components: { RainChart },
  data() {
    return {
      displayHours: 6,
      displayInterval: 10
    };
  },
  computed: {
    currentDate() {
      const baseDate = this.$store.getters.baseDate.clone();
      return baseDate
        .subtract(baseDate.minute() % 10, "minutes")
        .format("YYYY/MM/DD HH:mm");
    },
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    mesuredData() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      }
      return this.selectedObservatory.timeSeries.filter(v => v.type === "real");
    },
    predictData() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      }
      return this.selectedObservatory.timeSeries.filter(
        v => v.type === "forecast"
      );
    },
    isDeficit() {
      return this.mesuredData.length == 0;
    },
    timeSeries() {
      const displayStartTime = moment
        .utc(this.selectedObservatory.baseDate, "YYYY/MM/DD HH:mm")
        .subtract(this.displayInterval === 10 ? 3 : 23, "hours")
        .format("YYYY/MM/DD HH:mm");
      const displayLimitTime = moment
        .utc(this.selectedObservatory.baseDate, "YYYY/MM/DD HH:mm")
        .add(this.displayHours, "hours")
        .format("YYYY/MM/DD HH:mm");
      return this.selectedObservatory.timeSeries
        .filter(
          r =>
            r.date > displayStartTime &&
            r.date <= displayLimitTime &&
            (this.displayInterval === 10 ||
              (this.displayInterval === 60 && r.date.substr(-2) === "00"))
        )
        .map(r => {
          const time = moment
            .utc(r.date, "YYYY/MM/DD HH:mm")
            .local()
            .format("MM/DD HH:mm");
          return {
            time: time,
            predict: r.type === "forecast",
            min10:
              r.min10 !== undefined ? parseFloat(r.min10).toFixed(2) : "---",
            min60:
              r.min60 !== undefined ? parseFloat(r.min60).toFixed(2) : "---",
            total:
              r.total !== undefined ? parseFloat(r.total).toFixed(2) : "---"
          };
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.rain-level-detail {
  thead {
    background-color: #addff3;
  }
  tbody td {
    background: #eee;
  }
  tbody tr:nth-child(odd) td {
    background: #fff;
  }
  .predict {
    color: blue;
  }
}
</style>
