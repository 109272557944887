<template>
  <div style="height: 100%;">
    <div id="times-area" class="times-area" v-on:scroll="handleScroll">
      <div class="time white-space" ref="timeWhiteSpace" />
      <button
        class="time"
        v-for="(info, index) in selectedRiskContents"
        :key="index"
        :id="'time' + index"
        :class="{ active: index === highLightTimeIndex }"
        :ref="'time' + index"
        @click="handleClick(index)"
      >
        <span>
          <span class="date" v-if="moment.utc(info.date).isSame(riskBaseDate)">
            現在<br
          /></span>
          <span class="date" v-else
            >{{
              moment
                .utc(info.date)
                .local()
                .lang("ja", {
                  weekdays: ["日", "月", "火", "水", "木", "金", "土"]
                })
                .format("MM/DD (dd)")
            }}<br
          /></span>
          <span>{{
            moment
              .utc(info.date)
              .local()
              .format("HH:mm")
          }}</span>
        </span>
      </button>
      <div class="time white-space" />
    </div>
  </div>
</template>

<script>
import { Risk } from "../../../enums/Risk";
import moment from "moment";
import VueScrollTo from "vue-scrollto";

export default {
  name: "MobileTimeSeriesControl",
  data() {
    return {
      Risk,
      isPlay: false,
      moment,
      timeWhiteSpace: 0,
      highLightTimeIndex: 0,
      isScrolled: false
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    selectedRiskContents() {
      if (this.selectedRisk.key === Risk.RAIN.key) {
        return this.$store.getters.rainImageInfo;
      } else if (
        this.selectedRisk.key === Risk.RIVER_FLOODING.key &&
        this.$store.getters.riverFloodingInfo.length > 0
      ) {
        return this.$store.getters.riverFloodingInfo[0].images;
      } else if (
        this.selectedRisk.key === Risk.DOSYA.key &&
        this.$store.getters.dosyaInfo.images !== undefined
      ) {
        return this.$store.getters.dosyaInfo.images;
      } else if (
        this.selectedRisk.key === Risk.SNOW.key &&
        this.$store.getters.snowInfo.images !== undefined
      ) {
        return this.$store.getters.snowInfo.images;
      } else if (
        this.selectedRisk.key === Risk.KOUZUI.key &&
        this.$store.getters.kouzuiInfo !== undefined
      ) {
        return this.$store.getters.kouzuiInfo;
      } else {
        return [];
      }
    },
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    riskBaseDate() {
      return this.$store.getters.riskBaseDate;
    }
  },
  async mounted() {
    this.timeWhiteSpace = this.$refs.timeWhiteSpace.clientWidth;
  },
  watch: {
    selectedRiskContents() {
      this.scrollToCurrentTime();
    }
  },
  methods: {
    handleScroll(e) {
      if (this.isScrolled) {
        this.updateHighlightTimeIndex(e);
      }
    },
    handleClick(index) {
      this.isScrolled = false;
      this.scrollToIndex(index);
      this.isScrolled = true;
    },
    scrollToIndex: function(index) {
      this.executeScroll(index);
    },
    executeScroll: function(index) {
      let options = {
        container: "#times-area",
        easing: "linear",
        offset: -this.timeWhiteSpace,
        x: true,
        y: false,
        onDone: () => {
          this.isScrolled = true;
        }
      };
      VueScrollTo.scrollTo("#time" + index, 100, options);
    },
    updateHighlightTimeIndex: function(e) {
      let scrollAreaWidth = e.target.offsetWidth;
      let timeWidth = scrollAreaWidth / 4;
      let highlightTimeIndex = e.target.scrollLeft / timeWidth;

      this.highLightTimeIndex = Math.round(highlightTimeIndex);

      const content = this.selectedRiskContents[this.highLightTimeIndex];

      if (content) {
        this.$store.commit(
          "SET_SELECTED_DATE",
          moment.utc(content.date, "YYYY/MM/DD HH:mm")
        );
      }
    },
    next() {
      this.executeScroll(this.highLightTimeIndex++);
    },
    clickAutoPlay() {
      if (this.isPlay) {
        this.isPlay = false;
        clearInterval(this.interval);
        this.interval = null;
        return;
      }
      this.interval = setInterval(() => {
        if (this.selectedRiskContents.length - 2 >= this.highLightTimeIndex) {
          this.handleClick(this.highLightTimeIndex++);
        } else {
          this.isPlay = false;
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 1000);
      this.isPlay = true;
    },
    async scrollToCurrentTime() {
      await this.$nextTick();
      this.selectedRiskContents.forEach((row, index) => {
        if (
          moment.utc(row.date, "YYYY/MM/DD HH:mm").isSame(this.selectedDate)
        ) {
          this.scrollToIndex(index);
          this.highLightTimeIndex = index;
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../common";

$base-color: #2c324c;

.play-button-area {
  position: absolute;
  height: 10%;
  bottom: 0;
  left: 0;
  padding-left: 5px;
  display: inline-flex;
  align-items: center;
}

.times-area {
  overflow: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory; /* Chrome Canary */
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
}

.time {
  scroll-snap-align: center;
  padding: 5px;
  color: white;
  font-size: 13px;
  display: inline-block;
  vertical-align: bottom;
  background-color: rgba($base-color, 0.8);
  width: 25%;
  height: 100%;
  touch-action: manipulation;
  white-space: normal;
}

.time.active {
  background-color: orange;
  font-weight: bolder;
  color: $base-color;
}

.time .date {
  font-size: 10px;
}

.white-space {
  width: calc(50% - (25% / 2));
  touch-action: manipulation;
}

.overlay-gradients {
  bottom: 0;
  height: 10%;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(
    to right,
    rgba(#000000, 0.6),
    rgba(#000000, 0),
    rgba(#000000, 0),
    rgba(#000000, 0),
    rgba(#000000, 0),
    rgba(#000000, 0),
    rgba(#000000, 0.6)
  );
  pointer-events: none;
}
</style>
