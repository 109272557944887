<template>
  <div v-if="isLayerShow && markers.length > 0">
    <l-marker
      v-for="ob in markers"
      :key="ob.id"
      :lat-lng="[ob.lat, ob.lng]"
      :pane="pane"
    >
      <l-icon :icon-size="[36, 12]" :icon-anchor="[18, 6]">
        <div class="sensor-contents">
          <div v-if="ob.status === 'deficit'" class="deficit">欠測</div>
          <div v-else :style="getStatusStyle(ob.status)">
            {{ ob.level }}
            <span class="unit">cm</span>
          </div>
        </div>
      </l-icon>
    </l-marker>
  </div>
</template>
<script>
import { LIcon, LMarker } from "vue2-leaflet";
import { OBSERVATORY } from "@/enums/Type";
import { MapPane } from "../../../enums/Type";
export default {
  name: "ObservatorySensorLayer",
  components: {
    LIcon,
    LMarker
  },
  data() {
    return {
      pane: MapPane.ObservatorySendorMarkersLayer.name,
      markers: []
    };
  },
  computed: {
    observatories() {
      return this.$store.getters.observatories;
    },
    riskSubControl() {
      return this.$store.getters.riskSubControl;
    },
    map() {
      return this.$store.getters.leafletMap;
    },
    zoom() {
      return Math.floor(this.map.getZoom());
    },
    isLayerShow() {
      return this.riskSubControl.observatory.includes(
        OBSERVATORY.FLOODSENSOR.code
      );
    }
  },
  watch: {
    observatories() {
      this.fetchMarkers();
    }
  },
  created() {
    //
  },
  beforeDestroy() {
    //
  },
  methods: {
    fetchMarkers() {
      this.markers = [];
      this.$nextTick(() => {
        this.markers = this.$store.getters.observatories.filter(obs => {
          return (
            obs.type === OBSERVATORY.FLOODSENSOR.code &&
            obs.takeoSinsui !== true
          );
        });
      });
    },
    getStatusStyle(status) {
      return { color: status === "warning" ? "#ff8989" : "#ffffff" };
    }
  }
};
</script>
<style lang="scss" scoped>
.sensor-contents {
  color: white;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  text-shadow: -1px -1px 0 #333333, 1px -1px 0 #333333, -1px 1px 0 #333333,
    1px 1px 0 #333333;
  .deficit {
    font-size: 12px;
    color: #ededed;
    font-weight: normal;
  }
  .unit {
    font-size: 12px;
    margin-left: 3px;
    font-weight: normal;
  }
}
</style>
