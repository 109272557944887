<template>
  <v-card class="blue-grey darken-4 d-flex flex-column" flat tile>
    <div class="text-right mr-1 mt-1">
      <v-btn dark x-small icon @click="close()">
        <v-icon x-small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card
      class="white--text text-center"
      style="white-space: pre"
      height="70"
      color="transparent"
      flat
    >
      <h2>{{ appTitle1 }}</h2>
      <h4>{{ appTitle2 }}</h4>
    </v-card>
    <img class="mb-3" :src="AssetsImage" height="35" width="100%" />

    <v-card class="d-flex justify-center" height="34" color="transparent" flat>
      <template v-if="isPrivateMode">
        <v-btn
          v-for="tab in tabs"
          :key="tab.id"
          small
          tile
          color="#d0d3d6"
          width="155"
          class="mx-1"
          @click="selectedTabId = tab.id"
          :outlined="selectedTabId !== tab.id"
        >
          {{ tab.label }}
        </v-btn>
      </template>
    </v-card>

    <div style="height: calc(100dvh - 188px)">
      <div v-show="selectedTabId === 'alert'">
        <AlertContainer
          class="overflow-y-auto"
          style="height: calc(100dvh - 305px)"
        />
        <v-card
          class="mx-2 my-2 pa-1 white--text blue-grey darken-2"
          height="110"
          width="320"
          tile
          style="overflow-y: scroll"
          v-if="selectedTabId === 'alert'"
        >
          <div class="d-flex mb-1">
            <h5>お知らせ</h5>
            <div class="caption ml-auto" v-if="isPrivateMode">
              今月のアクセス数:
              {{ gaUser.total }}
              {{ gaUser.current ? `(${gaUser.current})` : "" }}
            </div>
          </div>
          <div class="caption" v-html="sysMsg"></div>
        </v-card>
      </div>
      <ChatContainer v-if="selectedTabId === 'chat'" />
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import AssetsImage from "@/assets/LogoLarge.svg";
import AlertContainer from "./AlertContainer";
import ChatContainer from "./chat/ChatContainer";
import { isPrivateMode } from "../utils/common";
export default {
  name: "RightSideContents",
  components: {
    AlertContainer,
    ChatContainer
  },
  data() {
    return {
      isPrivateMode,
      sysMsg: null,
      AssetsImage,
      tabs: [
        {
          id: "alert",
          label: "警報・注意報"
        },
        {
          id: "chat",
          label: "チャット"
        }
      ],
      selectedTabId: "alert",
      gaUser: {
        current: 0,
        total: 0
      }
    };
  },
  computed: {
    appTitle1() {
      return process.env.VUE_APP_TITLE.replace(" ", "").split("|")[0];
    },
    appTitle2() {
      return process.env.VUE_APP_TITLE.replace(" ", "").split("|")[1];
    },
    ymd() {
      return this.$store.getters.baseDate.format("YYYYMMDD");
    }
  },
  watch: {
    ymd() {
      this.loadGA();
    }
  },
  mounted() {
    this.loadSystemMessage();
    this.loadGA();
  },
  methods: {
    close() {
      this.$store.commit("SET_IS_RIGHT_NAV", false);
    },
    async loadSystemMessage() {
      this.sysMsg = null;
      const response = await axios
        .create({
          responseType: "json"
        })
        .get(`${location.origin}/sys_msg.json`);
      if (response.data && response.data.html) {
        this.sysMsg = response.data.html;
      }
    },
    async loadGA() {
      if (!isPrivateMode) {
        return;
      }
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
      };
      const params = {
        date: this.ymd
      };
      const res = await axios.post(
        "https://mnatlr4umi2fnbswxeffjlpx340aphyq.lambda-url.ap-northeast-1.on.aws/",
        params,
        { headers: headers }
      );

      if (res && res.status === 200) {
        this.gaUser = res.data;
      }
    }
  }
};
</script>

<style lang="scss">
//
</style>
