<script>
import { Bar } from "vue-chartjs";
import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";
import { STAGES } from "../../../enums/Type";
Chart.plugins.register(ChartAnnotationsPlugin);
import moment from "moment";
import _ from "lodash";

export default {
  extends: Bar,
  name: "StageChart",
  props: {
    selectedObservatory: {
      type: Object
    },
    displayHours: Number,
    levelUnit: String,
    standardLevelType: String,
    chartWaterBoxLevel: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      landform: [],
      dataCollection: {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "水位",
            unit: "m",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "level",
            borderWidth: 2,
            borderColor: "#01163F",
            backgroundColor: "#01163F",
            pointBorderColor: "#01163F",
            pointRadius: 2,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "水位",
            unit: "m",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "level",
            borderWidth: 3,
            borderColor: "#3734dc",
            backgroundColor: "#3734dc",
            pointBorderColor: "#3734dc",
            borderDash: [10, 5],
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "流域雨量指数",
            unit: "",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "flood_index",
            borderWidth: 2,
            borderColor: "#00BCD4",
            backgroundColor: "#00BCD4",
            pointBorderColor: "#00BCD4",
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          }
        ]
      },
      options: {
        layout: {
          padding: {
            right: 0,
            left: 0
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              return (
                label +
                " : " +
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] +
                " " +
                unit
              );
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "level",
              gridLines: {
                display: true
              },
              ticks: {
                max: 20,
                min: 0,
                stepSize: 1,
                callback: tick => {
                  return ("        " + tick).slice(-6);
                }
              }
            },
            {
              id: "flood_index",
              position: "right",
              gridLines: {
                display: false
              },
              display: false,
              ticks: {
                max: 20,
                min: 0
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              type: "time",
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                autoSkip: false
              },
              time: {
                unit: "minute",
                stepSize: 10,
                tooltipFormat: "MM/DD HH:mm",
                displayFormats: {
                  minute: "HH:mm"
                }
              },
              categoryPercentage: 1.0,
              barPercentage: 1.0
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "index",
          intersect: false
        },
        groundDataset: [],
        annotation: {
          events: ["click", "dblclick", "mouseover", "mouseout"],
          annotations: [
            {
              type: "box",
              drawTime: "beforeDatasetsDraw",
              yScaleID: "level",
              borderColor: "rgba(0,0,0,0)",
              yMin: 0,
              yMax: 0,
              backgroundColor: "#3D8AFF"
            },
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              value: "02:00",
              borderColor: "#FF0000",
              borderWidth: 1.5,
              label: {
                content: "現在",
                position: "top",
                enabled: true
              }
            }
          ]
        }
      }
    };
  },
  computed: {
    timeSeries() {
      if (this.selectedObservatory.timeSeries === undefined) {
        return [];
      } else {
        return this.selectedObservatory.timeSeries;
      }
    },
    baseDate() {
      return this.$store.getters.baseDate.clone();
    },
    minDate() {
      const d = this.baseDate.clone().subtract(200, "minutes");
      return d.subtract(d.minute() % 10, "minutes");
    },
    maxDate() {
      return this.baseDate.clone().add(this.displayHours, "hours");
    },
    standardLevelMax() {
      if (this.selectedObservatory.outbreakLevel !== undefined) {
        return this.selectedObservatory.outbreakLevel;
      } else if (this.selectedObservatory.dangerousLevel !== undefined) {
        return this.selectedObservatory.dangerousLevel;
      }
      return NaN;
    }
  },
  watch: {
    displayHours() {
      this.reRender();
    },
    selectedObservatory() {
      this.reRender();
    },
    standardLevelType() {
      this.reRender();
    }
  },
  mounted() {
    this.setTimeSeries();
    this.setYAxesLimitData();
    this.setWarningLevelStrokeData();
    this.setNowStrokeData();
    this.setWaterBoxData();
    this.addPlugin({
      id: "horizontalLine",
      beforeDatasetsDraw: chart => {
        const groundDataset = chart.config.options.groundDataset;
        if (groundDataset.length > 0) {
          const ctxPlugin = chart.chart.ctx;
          const xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
          const yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
          const minGround = _.minBy(groundDataset, o => {
            return o[0];
          });
          const maxGround = _.maxBy(groundDataset, o => {
            return o[0];
          });
          ctxPlugin.strokeStyle = "red";
          ctxPlugin.beginPath();
          ctxPlugin.moveTo(xAxe.left, yAxe.bottom);
          for (let i = 0; i < groundDataset.length; i++) {
            let x = groundDataset[i][0] - minGround[0];
            x = (x / (maxGround[0] - minGround[0])) * xAxe.width + xAxe.left;
            let y = yAxe.max - yAxe.min - (groundDataset[i][1] - yAxe.min);
            y = (y / (yAxe.max - yAxe.min)) * yAxe.height + yAxe.top;
            ctxPlugin.lineTo(x, y);
          }
          ctxPlugin.lineTo(xAxe.right, yAxe.bottom);
          ctxPlugin.lineTo(xAxe.left, yAxe.bottom);
          ctxPlugin.closePath();
          ctxPlugin.fillStyle = "#f1eeea";
          ctxPlugin.fill();
        }
      }
    });
    setTimeout(() => {
      this.renderChart(this.dataCollection, this.options);
    }, 500);
  },
  methods: {
    reRender() {
      this.options.annotation.annotations.splice(
        2,
        this.options.annotation.annotations.length - 2
      );
      this.setTimeSeries();
      this.setYAxesLimitData();
      this.setWarningLevelStrokeData();
      this.setNowStrokeData();
      this.setWaterBoxData();
      this.renderChart(this.dataCollection, this.options);
    },
    setTimeSeries() {
      this.dataCollection.labels = this.timeSeries.map(ts => {
        return moment
          .utc(ts.date, "YYYY/MM/DD HH:mm")
          .local()
          .toDate();
      });

      this.options.scales.xAxes[0].ticks.min = this.minDate.toDate();
      this.options.scales.xAxes[0].ticks.max = this.maxDate.toDate();

      // reals
      this.dataCollection.datasets[0].data = this.timeSeries.map(ts =>
        ts.type !== "real" || ts.level === undefined ? null : ts.level
      );

      // forecasts
      this.dataCollection.datasets[1].data = this.timeSeries.map(ts =>
        ts.type !== "forecast" || ts.level === undefined ? null : ts.level
      );

      // flood_index
      this.dataCollection.datasets[2].data = this.timeSeries.map(ts =>
        ts.flood_index === undefined ? null : ts.flood_index
      );
      const flood_indexs = this.dataCollection.datasets[2].data.filter(
        d => d !== null
      );
      if (flood_indexs.length > 0) {
        this.options.scales.yAxes[1].ticks.min = 0;
        let floodIndexMax = Math.floor(Math.max(...flood_indexs) + 2);
        if (
          this.selectedObservatory.f_index_level4 !== undefined &&
          floodIndexMax < this.selectedObservatory.f_index_level4
        ) {
          floodIndexMax = this.selectedObservatory.f_index_level4 + 10;
        }
        this.options.scales.yAxes[1].ticks.max = floodIndexMax;
        this.options.scales.yAxes[1].display = true;
      } else {
        this.options.scales.yAxes[1].display = false;
      }

      // unit
      this.dataCollection.datasets[0].unit = this.levelUnit;
      this.dataCollection.datasets[1].unit = this.levelUnit;

      // landform
      this.landform = [];
      if (
        this.selectedObservatory.landform &&
        this.selectedObservatory.landform.length > 0
      ) {
        this.landform = this.selectedObservatory.landform;
      } else if (this.selectedObservatory.bravegate === true) {
        this.landform = [
          [0, 0],
          [0.39, 0],
          [0.39, -25],
          [8.96, -25],
          [8.96, 1],
          [9.363, 1]
        ];
      }
      this.options.groundDataset = this.landform;
    },
    setYAxesLimitData() {
      let levels = this.dataCollection.datasets[0].data.concat(
        this.dataCollection.datasets[1].data
      );
      levels = levels.filter(l => l !== null).map(l => parseFloat(l));

      let min = _.min(levels) - 1;
      let max = _.max(levels) + 5;
      if (isNaN(max)) {
        max = this.standardLevelMax + 3;
      } else if (isNaN(this.standardLevelMax) === false) {
        max = Math.max(max, this.standardLevelMax + 3);
      }

      if (this.landform.length > 0) {
        const landformYMin = _.minBy(this.landform, o => {
          return o[1];
        });
        const landformYMax = _.maxBy(this.landform, o => {
          return o[1];
        });
        if (landformYMin) {
          const landformYMinValue = Math.floor(landformYMin[1] - 1);
          if (landformYMinValue < min) {
            min = landformYMinValue;
          }
        }
        if (landformYMax) {
          const landformYMaxValue = Math.floor(landformYMax[1] + 1);
          if (max < landformYMaxValue) {
            max = landformYMaxValue;
          }
        }
      }
      if (this.selectedObservatory.bravegate === true) {
        max = 20;
      } else if (
        isNaN(min) &&
        this.selectedObservatory.kiki === true &&
        this.chartWaterBoxLevel !== null
      ) {
        min = this.chartWaterBoxLevel - 1;
      }
      this.options.scales.yAxes[0].ticks.min = Math.floor(min);
      this.options.scales.yAxes[0].ticks.max = Math.floor(max);
      this.options.annotation.annotations[0].yMin = Math.floor(min);
    },
    setWarningLevelStrokeData() {
      const targets = [];
      let scaleID = "level";
      if (this.standardLevelType === "floodIndex") {
        scaleID = "flood_index";
        targets.push({
          color: STAGES.WARNING.color,
          label: "基準Ⅰ",
          value: this.selectedObservatory.f_index_level1
        });
        targets.push({
          color: STAGES.EVACUATION.color,
          label: "基準Ⅱ",
          value: this.selectedObservatory.f_index_level2
        });
        targets.push({
          color: STAGES.DANGEROUS.color,
          label: "基準Ⅲ",
          value: this.selectedObservatory.f_index_level3
        });
        targets.push({
          color: STAGES.OUTBREAK.color,
          label: "基準Ⅳ",
          value: this.selectedObservatory.f_index_level4
        });
      } else {
        targets.push({
          color: STAGES.STANDBY.color,
          label: STAGES.STANDBY.label,
          value: this.selectedObservatory.standbyLevel
        });
        targets.push({
          color: STAGES.WARNING.color,
          label: STAGES.WARNING.label,
          value: this.selectedObservatory.warningLevel
        });
        targets.push({
          color: STAGES.EVACUATION.color,
          label: STAGES.EVACUATION.label,
          value: this.selectedObservatory.evacuationLevel
        });
        targets.push({
          color: STAGES.DANGEROUS.color,
          label: STAGES.DANGEROUS.label,
          value: this.selectedObservatory.dangerousLevel
        });
        targets.push({
          color: STAGES.OUTBREAK.color,
          label: STAGES.OUTBREAK.label,
          value: this.selectedObservatory.outbreakLevel
        });
      }

      targets.forEach(info => {
        if (info.value !== undefined) {
          this.options.annotation.annotations.push(
            this.getAnnotationObject(info, scaleID)
          );
        }
      });
    },
    getAnnotationObject(info, scaleID) {
      return {
        type: "line",
        mode: "horizontal",
        scaleID: scaleID,
        value: info.value,
        borderColor: info.color,
        borderWidth: 2,
        label: {
          backgroundColor: "rgba(0,0,0,0)",
          yAdjust: -6,
          fontSize: 10,
          fontColor: "black",
          position: "right",
          enabled: true
        }
      };
    },
    setNowStrokeData() {
      this.options.annotation.annotations[1].value = this.baseDate
        .clone()
        .local()
        .toDate();
    },
    setWaterBoxData() {
      this.options.annotation.annotations[0].yMin = this.options.scales.yAxes[0].ticks.min;
      const levels = this.timeSeries
        .filter(ts => ts.type === "real" && ts.level !== undefined)
        .map(d => d.level);
      if (levels.length > 0) {
        this.options.annotation.annotations[0].yMax = levels[levels.length - 1];
      } else if (this.chartWaterBoxLevel !== null) {
        this.options.annotation.annotations[0].yMax = this.chartWaterBoxLevel;
      }
    }
  }
};
</script>

<style scoped></style>
