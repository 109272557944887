<template>
  <v-card class="pa-2" min-height="100">
    <div class="d-flex align-center">
      <div class="body-2 my-2 font-weight-bold">タイムラプス</div>
      <v-btn x-small class="ml-4" @click="showDialog">新規作成</v-btn>
      <v-btn
        x-small
        class="ml-auto"
        @click="deleteVideo()"
        :disabled="!rows.some(row => row.checked)"
        >削除</v-btn
      >
    </div>
    <div class="pa-3 caption text--secondary" v-if="rows.length === 0">
      未登録
    </div>
    <v-simple-table dense v-if="rows.length > 0">
      <thead>
        <tr>
          <th class="pl-2 pr-0 py-1" style="width: 40px">
            <v-checkbox
              hide-details
              dense
              :indeterminate="isIndeterminate"
              class="ma-0 pa-0"
              v-model="checkAll"
              @change="toggleAll()"
            ></v-checkbox>
          </th>
          <th class="text-left">期間</th>
          <th class="text-center" style="width: 100px">再生</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rows" :key="row.id">
          <td class="pl-2 pr-0" style="width: 40px">
            <v-checkbox
              hide-details
              dense
              class="ma-0 pa-0"
              v-model="row.checked"
            ></v-checkbox>
          </td>
          <td style="white-space: pre" class="caption">
            <div>{{ row.range }}</div>
          </td>
          <td class="text-center">
            <v-btn small text icon color="indigo" @click="playVideo(row.key)">
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="dialog" width="300" v-if="data">
      <v-card>
        <v-card-title>タイムラプス作成</v-card-title>
        <v-card-text class="pb-0">
          <div class="caption">開始日時</div>
          <v-card height="40" outlined class="pl-2">
            <datetime
              v-model="data.date"
              :max-datetime="currentDate"
              input-class="timelapse-datetime-picker"
              type="datetime"
              :minute-step="10"
              :disabled="loading"
            >
              <template slot="button-confirm" slot-scope="scope">
                <span v-if="scope.step === 'date'">
                  <v-btn text color="indigo">時刻選択</v-btn>
                </span>
                <span v-if="scope.step === 'time'">
                  <v-btn text color="indigo">OK</v-btn>
                </span>
              </template>
            </datetime>
          </v-card>
          <div class="caption mt-3">期間</div>
          <v-select
            v-model="data.hours"
            :items="hourItems"
            outlined
            dense
            hide-details
            class="mt-1"
            :disabled="loading"
          />
          <div class="caption py-2" style="white-space: pre">{{ range }}</div>
          <v-card tile flat height="20">
            <v-progress-linear
              indeterminate
              color="cyan"
              v-if="loading"
            ></v-progress-linear>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text @click="dialog = false" :disabled="loading">
            キャンセル
          </v-btn>
          <v-btn small @click="createVideo" color="primary" :disabled="loading">
            作成
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="videoDialog" width="590" v-if="videoUrl">
      <v-card>
        <v-card-title class="body-2 pa-3 py-2">タイムラプス再生</v-card-title>
        <v-card-text class="px-3 py-1">
          <video
            controls
            width="560"
            height="420"
            ref="previewVideo"
            :key="videoKey"
          >
            <source :src="videoUrl" type="video/mp4" />
          </video>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn small text @click="videoDialog = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import axios from "axios";
import * as s3 from "../../../utils/s3";
import { isAuthenticated } from "../../../utils/cognito";
export default {
  name: "CameraTimeLapse",
  data() {
    return {
      rows: [],
      checkAll: false,
      dialog: false,
      videoDialog: false,
      videoUrl: "",
      data: null,
      currentDate: moment()
        .utc()
        .toISOString(),
      hourItems: [
        { text: "3時間", value: 3 },
        { text: "6時間", value: 6 },
        { text: "12時間", value: 12 },
        { text: "24時間", value: 24 }
      ],
      loading: false,
      videoKey: 0
    };
  },

  props: {
    observatory: {
      type: Object,
      required: true
    }
  },

  watch: {
    osid(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.load();
      }
    }
  },

  created() {
    this.load();
  },

  mounted() {
    //
  },

  computed: {
    osid() {
      return this.observatory.id;
    },
    isIndeterminate() {
      const checkedCount = this.rows.filter(row => row.checked).length;
      return checkedCount >= 1 && checkedCount < this.rows.length;
    },
    range() {
      if (!this.data) return "";
      const start = moment(this.data.date);
      let end = start.clone().add(this.data.hours, "hours");
      if (end.isAfter(moment())) {
        end = moment().subtract(moment().minute() % 10, "minutes");
      }
      return (
        start.format("YYYY年MM月DD日 HH:mm") +
        " -\n" +
        end.format("YYYY年MM月DD日 HH:mm")
      );
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },

  methods: {
    toggleAll() {
      this.rows.forEach(row => (row.checked = this.checkAll));
    },
    async load() {
      const keys = await s3.getTimelapseKeys(this.osid);
      this.rows = keys.map(key => {
        let range = "";
        const arr = key.split("/");
        if (arr.length >= 2) {
          range = arr[arr.length - 1].replace(".mp4", "");
          const start = moment.utc(range.split("-")[0], "YYYYMMDDHHmm");
          const end = moment.utc(range.split("-")[1], "YYYYMMDDHHmm");
          range = start.add(9, "hours").format("YYYY年MM月DD日 HH:mm") + " -\n";
          range += end.add(9, "hours").format("YYYY年MM月DD日 HH:mm");
        }
        return {
          checked: false,
          key: key,
          range: range
        };
      });
    },
    showDialog() {
      this.data = {
        date: this.baseDate
          .subtract(3, "hours")
          .subtract(this.baseDate.minute() % 10, "minutes")
          .toISOString(),
        hours: 3
      };
      this.dialog = true;
    },
    async createVideo() {
      if (!this.data) return;
      const date1 = moment(this.data.date);
      let date2 = date1.clone().add(this.data.hours, "hours");
      if (date2.isAfter(moment.utc())) {
        date2 = moment.utc().subtract(date2.minute() % 10, "minutes");
      }
      const bucket = this.observatory.bucket;
      const prefix = this.observatory.prefix;

      const auth = await isAuthenticated();
      const token = auth.idToken.jwtToken;
      const userId = auth.idToken.payload["cognito:username"];

      if (!this.osid || !bucket || !userId || !token) return;

      const params = {
        userId: userId,
        osid: this.osid,
        date1: date1.subtract(9, "hours").format("YYYYMMDDHHmm"),
        date2: date2.subtract(9, "hours").format("YYYYMMDDHHmm"),
        bucket: bucket,
        prefix: prefix
      };

      const headers = {
        Authorization: token,
        "Content-Type": "application/x-www-form-urlencoded"
      };
      this.loading = true;
      const res = await axios
        .post(
          "https://yklmrvtammvmtq66eqjdoghbxe0dwsby.lambda-url.ap-northeast-1.on.aws/",
          params,
          { headers: headers }
        )
        .catch(err => {
          console.error(err);
          return null;
        })
        .finally(() => {
          this.loading = false;
        });
      if (res && res.status === 200) {
        this.dialog = false;
        this.load();
      }
    },
    async playVideo(key) {
      const url = await s3.getSignedUrl(key);
      console.log(url);
      this.videoUrl = url;
      this.videoKey++;
      this.videoDialog = true;
      // setTimeout(() => {
      //   this.$refs.previewVideo.play();
      // }, 1000);
    },
    async deleteVideo() {
      const keys = this.rows.filter(row => row.checked).map(row => row.key);
      console.log(keys);
      if (keys.length === 0) return;
      if (!confirm("選択したタイムラプスを削除しますか？")) return;
      await s3.removeObjects(keys);
      this.load();
    }
  }
};
</script>

<style lang="scss">
//
</style>

<style lang="scss">
.timelapse-datetime-picker {
  cursor: pointer;
  background-color: white;
  color: rgb(85, 85, 85);
  font-size: 16px;
  padding: 0;
  margin: 0;
  height: 36px;
}
</style>
