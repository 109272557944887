const staticConfig = {
  tileServer: {
    url: "https://cyberjapandata.gsi.go.jp"
  }
};

const cognitoConfig = {
  Region: "ap-northeast-1",
  UserPoolId: "ap-northeast-1_ORa286oxV",
  ClientId: "3t9sudt2rjc9kegfppldb68je8",
  IdentityPoolId: "ap-northeast-1:80fff204-a8ea-4904-ad21-c8a4d7449be9"
};

const apiStage = process.env.VUE_APP_API_STAGE;
const chatConfig = {
  channel: apiStage === "dev" ? "shiroishi-dev" : "shiroishi",
  webSocketUrl: `wss://cm9aqzcon5.execute-api.ap-northeast-1.amazonaws.com/${apiStage}/`,
  webPushUrl: `https://jbzk8hu651.execute-api.ap-northeast-1.amazonaws.com/${apiStage}/api`
};

export { staticConfig, cognitoConfig, chatConfig };
