import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { BaseTile } from "../enums/BaseTile";
import { Risk } from "../enums/Risk";
import * as axiosHelper from "../utils/axiosHelper";
import moment from "moment";
import { OBSERVATORY, FloodSensorTakeoSinsui } from "../enums/Type";
import * as s3 from "../utils/s3";
import { isPublicMode } from "../utils/common";

const DATE_FORMAT = "YYYY/MM/DD HH:mm";

export default new Vuex.Store({
  state: {
    mobileHazardMap: {
      key: null,
      detail: []
    },
    isShowMobileHazardMap: false,
    user: {},
    baseDate: moment.utc(),
    selectedRisk: Risk.RAIN,
    selectedBaseTile: BaseTile.GRAY,
    selectedFeatures: [],
    selectedObservatory: {},
    selectedDate: moment.utc(),
    riskBaseDate: moment.utc(),
    leafletMap: null,
    isShowMapDetail: false,
    mapDetail: {
      title: "",
      contents: ""
    },
    observatories: [],
    isShowUsageGuide: false,
    imageSizeInfo: {},
    rainImageCoordinate: {},
    rainImageInfo: [],
    riskSubControl: {
      observatory: [
        OBSERVATORY.STAGE.code,
        OBSERVATORY.RAIN.code,
        OBSERVATORY.CAMERA.code,
        OBSERVATORY.DAM.code,
        OBSERVATORY.FLOODSENSOR.code,
        OBSERVATORY.TIDE.code
      ],
      opacity: 0.7
    },
    riverFloodingInfo: [],
    riverFloodingType: 3,
    dosyaInfo: {
      bounds: [],
      images: []
    },
    snowInfo: {
      bounds: [],
      images: []
    },
    usageGuide: {
      title: null,
      colors: []
    },
    hazardMapUsageGuide: {
      url: []
    },
    alerts: [],
    isNavigationVisible: false,
    isRightNavVisible: false,
    isMonitoring: true,
    isCameraMonitor: false,
    kouzuiInfo: [],
    isLoading: false,
    wind: {
      timeSeries: [],
      data: {}
    },
    selectedWind: {
      speed: 0,
      direction: 0
    },
    isMobileObservatoryRisk: true,
    chatThumbnails: []
  },
  getters: {
    mobileHazardMap: state => state.mobileHazardMap,
    baseDate: state => state.baseDate,
    selectedRisk: state => state.selectedRisk,
    selectedBaseTile: state => state.selectedBaseTile,
    selectedFeatures: state => state.selectedFeatures,
    selectedObservatory: state => state.selectedObservatory,
    selectedDate: state => state.selectedDate,
    riskBaseDate: state => state.riskBaseDate,
    imageSizeInfo: state => state.imageSizeInfo,
    rainImageInfo: state => state.rainImageInfo,
    rainImageCoordinate: state => state.rainImageCoordinate,
    leafletMap: state => state.leafletMap,
    isShowMapDetail: state => state.isShowMapDetail,
    mapDetail: state => state.mapDetail,
    observatories: state => state.observatories,
    riskSubControl: state => state.riskSubControl,
    isShowUsageGuide: state => state.isShowUsageGuide,
    riverFloodingInfo: state => state.riverFloodingInfo,
    riverFloodingType: state => state.riverFloodingType,
    dosyaInfo: state => state.dosyaInfo,
    snowInfo: state => state.snowInfo,
    usageGuide: state => state.usageGuide,
    hazardMapUsageGuide: state => state.hazardMapUsageGuide,
    alerts: state => state.alerts,
    isShowMobileHazardMap: state => state.isShowMobileHazardMap,
    isNavigationVisible: state => state.isNavigationVisible,
    isRightNavVisible: state => state.isRightNavVisible,
    isMonitoring: state => state.isMonitoring,
    isCameraMonitor: state => state.isCameraMonitor,
    kouzuiInfo: state => state.kouzuiInfo,
    isLoading: state => state.isLoading,
    wind: state => state.wind,
    selectedWind: state => state.selectedWind,
    isMobileObservatoryRisk: state => state.isMobileObservatoryRisk,
    chatThumbnails: state => state.chatThumbnails
  },
  mutations: {
    SET_MOBILE_HAZARD_MAP: function(state, payload) {
      state.mobileHazardMap = payload;
    },
    SET_BASE_DATE: function(state, payload) {
      state.baseDate = payload;
    },
    SET_SELECTED_RISK: function(state, payload) {
      state.selectedRisk = payload;
    },
    SET_SELECTED_FEATURES: function(state, payload) {
      state.selectedFeatures = payload;
    },
    SET_SELECTED_BASE_TILE: function(state, payload) {
      state.selectedBaseTile = payload;
    },
    SET_LEAFLET_MAP: function(state, payload) {
      state.leafletMap = payload;
    },
    SET_ALERTS: function(state, payload) {
      state.alerts = payload;
    },
    SET_OBSERVATORIES: function(state, payload) {
      state.observatories = payload;
    },
    SET_SELECTED_OBSERVATORY: function(state, payload) {
      state.selectedObservatory = payload;
    },
    SET_SELECTED_DATE: function(state, payload) {
      state.selectedDate = payload;
    },
    SET_RAIN_IMAGE_COORDINATE: function(state, payload) {
      state.rainImageCoordinate = payload;
    },
    SET_RAIN_IMAGE_INFO: function(state, payload) {
      state.rainImageInfo = payload;
    },
    SET_IMAGE_SIZE_INFO: function(state, payload) {
      state.imageSizeInfo = payload;
    },
    SET_RISK_BASE_DATE: function(state, payload) {
      state.riskBaseDate = payload;
    },
    SET_MAP_DETAIL: function(state, payload) {
      state.isShowMapDetail = payload.isShowing;
      state.mapDetail.title = payload.title;
      state.mapDetail.contents = payload.contents;
    },
    SET_RISK_SUB_CONTROL(state, payload) {
      const cloneRiskSubControl = Object.assign({}, state.riskSubControl);
      if (payload.observatory !== undefined) {
        cloneRiskSubControl.observatory = payload.observatory;
      }
      if (payload.opacity !== undefined) {
        cloneRiskSubControl.opacity = payload.opacity;
      }
      state.riskSubControl = cloneRiskSubControl;
    },
    ["SET_IS_SHOW_USAGE_GUIDE"](state, payload) {
      state.isShowUsageGuide = payload;
    },
    ["SET_SELECTED_AROUND_OBSERVATORIES"](state, payload) {
      state.selectedAroundObservatories = payload.observatories;
    },
    ["SET_RIVER_FLOODING_INFO"](state, payload) {
      state.riverFloodingInfo = payload;
    },
    SET_RIVER_FLOODING_TYPE: function(state, payload) {
      state.riverFloodingType = payload;
    },
    SET_DOSYA_INFO: function(state, payload) {
      state.dosyaInfo = payload;
    },
    SET_SNOW_INFO: function(state, payload) {
      state.snowInfo = payload;
    },
    SET_COGNITO_USER_INFO: function(state, payload) {
      state.user = payload;
    },
    SET_USAGE_GUIDE(state, payload) {
      state.usageGuide = payload;
    },
    SET_HAZARD_MAP_USAGE_GUIDE: function(state, payload) {
      state.hazardMapUsageGuide = payload;
    },
    SET_IS_SHOW_MOBILE_HAZARD_MAP: function(state, payload) {
      state.isShowMobileHazardMap = payload;
    },
    SET_IS_NAVIGATION: function(state, payload) {
      state.isNavigationVisible = payload;
    },
    SET_IS_RIGHT_NAV: function(state, payload) {
      state.isRightNavVisible = payload;
    },
    SET_IS_MONITORING: function(state, payload) {
      state.isMonitoring = payload;
    },
    SET_IS_CAMERA_MONITOR: function(state, payload) {
      state.isCameraMonitor = payload;
    },
    SET_KOUZUI_INFO: function(state, payload) {
      state.kouzuiInfo = payload;
    },
    SET_IS_LOADING: function(state, payload) {
      state.isLoading = payload;
    },
    SET_WIND_TIME_SERIES: function(state, payload) {
      state.wind.timeSeries = payload;
    },
    SET_WIND_DATA: function(state, payload) {
      state.wind.data = payload;
    },
    SET_SELECTED_WIND: function(state, payload) {
      state.selectedWind = payload;
    },
    SET_IS_MOBILE_OBSERVATORY_RISK: function(state, payload) {
      state.isMobileObservatoryRisk = payload;
    },
    SET_CHAT_THUMBNAILS: (state, payload) => {
      state.chatThumbnails = payload;
    }
  },
  actions: {
    FETCH_OBSERVATORIES: async function(context) {
      let observatories = [];
      if (this.state.observatories.length === 0) {
        const frontConfig = await s3.getFrontConfig();
        const visibleCameras =
          frontConfig.status === "warning"
            ? frontConfig.warnings
            : frontConfig.normals;
        const res = await axiosHelper.get("/observatories", {
          domain: process.env.VUE_APP_DOMAIN
        });
        if (res === null || !res.observatories) {
          context.commit("SET_OBSERVATORIES", []);
          return;
        }
        observatories = res.observatories;
        if (isPublicMode === true) {
          observatories = observatories.filter(obs => {
            if (obs.type === OBSERVATORY.CAMERA.code) {
              return visibleCameras.includes(obs.id);
            } else {
              return true;
            }
          });
        }
        observatories.forEach(obs => {
          if (obs.type === OBSERVATORY.STAGE.code) {
            if (obs.name.endsWith("(危)")) {
              obs.name = obs.name.replace("(危)", "");
            }
          }
        });
        observatories.forEach(obs => {
          if (
            obs.type === OBSERVATORY.STAGE.code &&
            obs.id.startsWith("99_41000_1_")
          ) {
            obs.type = OBSERVATORY.FLOODSENSOR.code;
            obs.status = "normal";
          } else if (
            obs.type === OBSERVATORY.STAGE.code &&
            obs.id.startsWith("99_41000_2_10000")
          ) {
            obs.type = OBSERVATORY.TIDE.code;
            obs.status = "normal";
            obs.dynamodb = "D_OBSERVATORY_TIDE";
          } else if (
            obs.type === OBSERVATORY.STAGE.code &&
            FloodSensorTakeoSinsui.some(r => r.id === obs.id)
          ) {
            obs.type = OBSERVATORY.FLOODSENSOR.code;
            obs.status = "normal";
            obs.takeoSinsui = true;
          } else if (
            obs.type === OBSERVATORY.CAMERA.code ||
            obs.type === OBSERVATORY.LIVECAMERA.code ||
            obs.type === OBSERVATORY.DAM.code
          ) {
            obs.status = "normal";
          } else {
            obs.status = "deficit";
          }
        });
      } else {
        observatories = [...this.state.observatories];
      }

      const baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 5, "minutes");

      const params = {
        domain: process.env.VUE_APP_DOMAIN,
        date: baseDate.format(DATE_FORMAT)
      };
      const response = await axiosHelper.get(
        "/observatories/status/v2",
        params
      );
      if (response !== null) {
        const rains = response.rains;
        const stages = response.stages;
        observatories.forEach(obs => {
          const obsId = obs.id;
          if (obs.type === OBSERVATORY.RAIN.code) {
            obs.status = "deficit";
            delete obs.min10;
            delete obs.total;
            if (rains[obsId] !== undefined) {
              if (rains[obsId].min10 !== undefined) {
                const min10 = rains[obsId].min10;
                obs.min10 = min10;
                if (min10 >= 20) {
                  obs.status = "4";
                } else if (min10 >= 10) {
                  obs.status = "3";
                } else if (min10 >= 5) {
                  obs.status = "2";
                } else if (min10 >= 1) {
                  obs.status = "1";
                } else if (min10 >= 0) {
                  obs.status = "0";
                }
              }
              if (rains[obsId].total !== undefined) {
                obs.total = rains[obsId].total;
              }
            }
          } else if (obs.type === OBSERVATORY.STAGE.code) {
            obs.status = "deficit";
            delete obs.level;
            delete obs.chartWaterBoxLevel;
            if (
              stages[obsId] !== undefined &&
              stages[obsId].level !== undefined
            ) {
              const level = stages[obsId].level;
              const date = stages[obsId].date;
              const targetDate = baseDate
                .clone()
                .subtract(30, "minutes")
                .format(DATE_FORMAT);
              if (obs.kiki === true && date < targetDate) {
                obs.chartWaterBoxLevel = level;
              } else {
                obs.level = level;
                if (
                  obs.outbreakLevel !== undefined &&
                  level >= obs.outbreakLevel
                ) {
                  obs.status = "outbreak";
                } else if (
                  obs.dangerousLevel !== undefined &&
                  level >= obs.dangerousLevel
                ) {
                  obs.status = "dangerous";
                } else if (
                  obs.evacuationLevel !== undefined &&
                  level >= obs.evacuationLevel
                ) {
                  obs.status = "evacuation";
                } else if (
                  obs.warningLevel !== undefined &&
                  level >= obs.warningLevel
                ) {
                  obs.status = "warning";
                } else if (
                  obs.standbyLevel !== undefined &&
                  level >= obs.standbyLevel
                ) {
                  obs.status = "standby";
                } else {
                  obs.status = "normal";
                }
              }
            }
          } else if (obs.type === OBSERVATORY.FLOODSENSOR.code) {
            if (obs.takeoSinsui === true) {
              obs.status = "normal";
            } else {
              obs.status = "deficit";
              delete obs.level;
              if (
                stages[obsId] !== undefined &&
                stages[obsId].level !== undefined
              ) {
                obs.level = stages[obsId].level;
                obs.status = "normal";
              }
            }
          } else if (obs.type === OBSERVATORY.TIDE.code) {
            obs.status = "normal";
          }
        });
      }
      context.commit("SET_OBSERVATORIES", observatories);
    },
    FETCH_SELECTED_OBSERVATORY_DATA: async function(context, target) {
      const observatory = Object.assign({}, target);
      const baseDate = this.state.baseDate.clone();

      if (
        observatory.type === OBSERVATORY.FLOODSENSOR.code &&
        observatory.takeoSinsui === true
      ) {
        const finded = FloodSensorTakeoSinsui.find(
          r => r.id === observatory.id
        );
        if (finded !== undefined) {
          window.open(finded.url, "_blank");
          return;
        }
      }

      const params = {
        date: baseDate.format(DATE_FORMAT),
        id: observatory.id,
        type:
          observatory.type === OBSERVATORY.FLOODSENSOR.code ||
          observatory.type === OBSERVATORY.TIDE.code
            ? OBSERVATORY.STAGE.code
            : observatory.type,
        rain_forecast: observatory.type === 1,
        // with_flood_index: observatory.type === OBSERVATORY.STAGE.code
      };
      if (observatory.type === 1) {
        params.hours = "24";
      }

      if (observatory.dynamodb) {
        params["dynamodb"] = observatory.dynamodb;
      }
      if (observatory.bucket) {
        params["bucket"] = observatory.bucket;
      }
      if (observatory.prefix) {
        params["prefix"] = observatory.prefix;
      }

      const response = await axiosHelper.get("/observatories/data", params);
      if (response === null) {
        throw new Error("fetch failed : observatories/data");
      }

      const selected = Object.assign({}, target);
      selected.baseDate = response.baseDate;
      selected.timeSeries = response.timeSeries;

      // flood_index
      if (observatory.type === OBSERVATORY.STAGE.code) {
        const hasFloodIndex = selected.timeSeries.some(
          v => v.flood_index !== undefined
        );
        if (hasFloodIndex) {
          const floodIndexResponse = await axiosHelper.get(
            "/observatories/flood_index",
            {
              id: observatory.id,
              date: baseDate.format(DATE_FORMAT)
            }
          );
          if (floodIndexResponse.timeSeries) {
            floodIndexResponse.timeSeries.forEach(f => {
              const finded = selected.timeSeries.find(ts => ts.date === f.date);
              if (finded !== undefined) {
                finded.flood_index = f.flood_index;
              } else {
                selected.timeSeries.push({
                  date: f.date,
                  flood_index: f.flood_index,
                  type: f.type
                });
              }
            });
            // sort
            selected.timeSeries.sort((a, b) => {
              return a.date < b.date ? -1 : 1;
            });
          }
        }
      }

      let contents = "";
      let title = "";
      const observatoryName = selected.name === undefined ? "" : selected.name;
      switch (selected.type) {
        case OBSERVATORY.RAIN.code:
          contents = "rainfall-detail";
          title = `${OBSERVATORY.RAIN.name}観測所 - ${observatoryName}`;
          break;
        case OBSERVATORY.STAGE.code:
          contents = "stage-detail";
          title = `${OBSERVATORY.STAGE.name}観測所 - ${observatoryName}`;
          break;
        case OBSERVATORY.FLOODSENSOR.code:
          contents = "stage-detail";
          title = `浸水情報 - ${observatoryName}`;
          break;
        case OBSERVATORY.TIDE.code:
          contents = "stage-detail";
          title = `潮位情報 - ${observatoryName}`;
          break;
        case OBSERVATORY.DAM.code:
          contents = "dam-detail";
          title = `${OBSERVATORY.DAM.name}観測所 - ${observatoryName}`;
          break;
        case OBSERVATORY.CAMERA.code:
          contents = "camera-detail";
          title = `${OBSERVATORY.CAMERA.name}`;
          break;
        case OBSERVATORY.LIVECAMERA.code:
          contents = "live-camera-detail";
          title = `${OBSERVATORY.LIVECAMERA.name}`;
          break;
      }
      context.commit("SET_SELECTED_OBSERVATORY", selected);

      context.commit("SET_MAP_DETAIL", {
        isShowing: true,
        title: title,
        contents: contents
      });
    },
    FETCH_RIVER_FLOODING: async function(context) {
      const baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 5, "minutes");

      const parameters = {
        date: baseDate.utc().format(DATE_FORMAT)
      };

      const response = await axiosHelper.get("/river-floods", parameters);
      if (response === null) {
        throw new Error("fetch failed : river-floods");
      }

      context.commit(
        "SET_RISK_BASE_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );
      const areas = response.areas.map(area => area);
      context.commit("SET_RIVER_FLOODING_INFO", areas);

      context.commit(
        "SET_SELECTED_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );
    },
    FETCH_DOSYA: async function(context) {
      const baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");

      const params = {
        date: baseDate.utc().format(DATE_FORMAT)
      };
      const res = await axiosHelper.get("/dosya", params);
      if (res === null) {
        context.commit("SET_DOSYA_INFO", {});
        return;
      }
      const dosyaInfo = {
        bounds: [],
        imageInfos: [],
        size: {}
      };
      if (!res.imageInfos) {
        context.commit("SET_DOSYA_INFO", dosyaInfo);
        return;
      }

      dosyaInfo.bounds = res.bounds;

      const loadImage = src => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = e => reject(e);
          image.src = src;
        });
      };

      const infos = res.imageInfos.map(async info => {
        const image = await loadImage(info.url);
        return {
          date: info.date,
          image: image,
          url: info.url
        };
      });
      const infosRes = await Promise.all(infos);
      dosyaInfo.images = infosRes.filter(row => row.image !== null);
      context.commit("SET_DOSYA_INFO", dosyaInfo);

      context.commit(
        "SET_RISK_BASE_DATE",
        moment.utc(res.baseDate, DATE_FORMAT)
      );

      context.commit(
        "SET_SELECTED_DATE",
        res.baseDate ? moment.utc(res.baseDate, DATE_FORMAT) : baseDate
      );
    }
  }
});
