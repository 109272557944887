<template>
  <div class="observatory-usage-guide">
    <div
      v-for="(usageItem, index) in usageGuide"
      :key="index"
      class="legend-area"
    >
      <div class="legend-title" v-if="usageItem.title">
        {{ usageItem.title }}
      </div>
      <v-card class="legend" tile elevation="5">
        <div
          v-for="(o, index) in usageItem.colors"
          :key="index"
          class="val"
          :style="{ backgroundColor: o.color }"
        >
          {{ o.value }}
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ObservatoryUsageGuide",
  data() {
    return {
      usageGuide: [
        {
          title: "水位",
          colors: [
            { color: "#000000", value: "氾濫発生水位" },
            { color: "#ad02ad", value: "氾濫危険水位" },
            { color: "#ff2800", value: "避難判断水位" },
            { color: "#ffa500", value: "氾濫注意水位" },
            { color: "#008000", value: "水防団待機水位" },
            { color: "#1e709f", value: "平常" },
            { color: "#AAAAAA", value: "休止・欠測等" }
          ]
        },
        {
          title: "雨量(mm/10分)",
          colors: [
            { color: "#FF0000", value: "20～" },
            { color: "#800080", value: "10～19" },
            { color: "#D88100", value: "5～9" },
            { color: "#1E42AF", value: "1～4" },
            { color: "#233559", value: "0" },
            { color: "#AAAAAA", value: "休止・欠測等" }
          ]
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.observatory-usage-guide {
  overflow: hidden;
  padding-bottom: 10px;
}
.legend-area {
  float: right;
  margin-left: 5px;
  .legend-title {
    text-align: right;
    font-size: 12px;
    font-weight: bold;
    white-space: pre;
    text-shadow: 1px 1px 0 #ffffff, -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
      -1px -1px 0 #ffffff;
  }
  .legend {
    float: left;
    .val {
      color: #ffffff;
      text-shadow: 1px 1px 0 #2c2c2c, -1px 1px 0 #2c2c2c, 1px -1px 0 #2c2c2c,
        -1px -1px 0 #2c2c2c;
      font-weight: bold;
      font-size: 12px;
      min-width: 12px;
      text-align: center;
      line-height: 1;
      padding: 0 10px;
      line-height: 20px;
      height: 20px;
      opacity: 0.9;
    }
  }
}
</style>
