<template>
  <div>
    <template v-if="isMsm || zoom <= 9">
      <RainWideLayer :boundsList="boundsList" />
    </template>
    <template v-else>
      <LImageOverlay
        v-for="(layer, key) in tileLayers"
        :key="key"
        :url="layer.url"
        :bounds="layer.bounds"
        :opacity="opacity"
      />
    </template>
    <LRectangle
      v-if="false"
      :fill="false"
      color="red"
      :bounds="currentTileBounds"
    />
  </div>
</template>
<script>
import { LImageOverlay, LRectangle } from "vue2-leaflet";
import axios from "axios";
import moment from "moment";
import L from "leaflet";
import RainWideLayer from "./RainWideLayer";

export default {
  name: "RainLayer",
  components: {
    LImageOverlay,
    LRectangle,
    RainWideLayer
  },
  data() {
    return {
      tiles: [],
      tileImages: [],
      boundsList: [],
      currentTileBounds: null,
      currentBaseDate: null
    };
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    opacity() {
      return this.$store.getters.riskSubControl.opacity;
    },
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    baseDate() {
      const baseDate = this.$store.getters.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 5, "minutes");
      return baseDate.utc().format("YYYY/MM/DD HH:mm");
    },
    rains() {
      return this.$store.getters.rainImageInfo;
    },
    selectedRain() {
      const d = this.selectedDate.clone().utc();
      const date = d.format("YYYY/MM/DD HH:mm");
      return this.rains.find(r => r.date === date);
    },
    selectedRainType() {
      return this.selectedRain ? this.selectedRain.type : "";
    },
    isMsm() {
      return this.selectedRainType === "msm";
    },
    tileUrl() {
      if (this.selectedRainType === "msm") {
        return "";
      }
      return this.selectedRain ? this.selectedRain.tileUrl : "";
    },
    zoom() {
      return Math.floor(this.map.getZoom());
    },
    tileLayers() {
      if (!this.tileUrl || this.zoom <= 9) {
        return [];
      }
      return this.tiles.map(tile => {
        return {
          bounds: tile.bounds,
          url: this.tileUrl.replace("{code}", tile.code)
        };
      });
    }
  },
  watch: {
    baseDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentBaseDate = null;
        this.sync();
      }
    }
  },
  async created() {
    await this.sync();
    this.map.on("moveend", this.mapMoveend);
  },
  mounted() {
    this.$store.commit("SET_USAGE_GUIDE", {
      title: "レーダ・予測雨量\n(mm/h)",
      colors: [
        { color: "#ba206e", value: "80" },
        { color: "#ff3700", value: "50" },
        { color: "#ffa021", value: "30" },
        { color: "#fbf43c", value: "20" },
        { color: "#0041FF", value: "10" },
        { color: "#218CFF", value: "5" },
        { color: "#A0D2FF", value: "1" },
        { color: "#99FFFF", value: "0" }
      ]
    });
  },
  beforeDestroy() {
    this.map.off("moveend", this.mapMoveend);
  },
  methods: {
    async mapMoveend() {
      if (this.currentTileBounds !== null) {
        const contained = this.currentTileBounds.contains(this.map.getBounds());
        if (contained === true) {
          return;
        }
      }
      if (this.zoom > 9) {
        this.sync();
      }
    },
    async sync() {
      const northwest = this.map.getBounds().getNorthWest();
      const southeast = this.map.getBounds().getSouthEast();

      const params = {
        northwest_lat: northwest.lat,
        northwest_lng: northwest.lng,
        southeast_lat: southeast.lat,
        southeast_lng: southeast.lng,
        date: this.baseDate
      };
      const url = "https://data.riskma.net/bosai/rain";
      // const url = "https://sl3mgfgby8.execute-api.ap-northeast-1.amazonaws.com/dev/bosai/rain";
      const response = await axios.get(url, {
        params
      });
      if (response === null) {
        this.$store.commit("SET_RAIN_IMAGE_INFO", []);
        return;
      }
      this.boundsList = response.data.bounds;

      if (this.zoom > 9) {
        this.tiles = response.data.tiles;
      } else {
        this.tiles = [];
      }
      this.loadCurrentTileBounds(northwest, southeast);

      // download tile urls
      const urls = [];
      response.data.rains.forEach(r => {
        this.tiles.forEach(tile => {
          if (r.tileUrl) {
            urls.push(r.tileUrl.replace("{code}", tile.code));
          }
        });
      });
      this.tileImages = urls.map(url => {
        const image = new Image();
        image.src = url;
        return image;
      });

      // download wide urls
      const rainImageInfo = response.data.rains.map(r => {
        const image = new Image();
        image.src = r.url;
        r.image = image;
        return r;
      });
      const responseBaseDate = moment.utc(
        response.data.baseDate,
        "YYYY/MM/DD HH:mm"
      );
      this.$store.commit("SET_RISK_BASE_DATE", responseBaseDate.clone());
      if (this.currentBaseDate === null) {
        this.currentBaseDate = responseBaseDate;
        this.$store.commit("SET_SELECTED_DATE", responseBaseDate.clone());
      }
      this.$store.commit("SET_RAIN_IMAGE_INFO", rainImageInfo);
    },
    loadCurrentTileBounds(queryNorthwest, querySoutheast) {
      if (this.tiles.length === 0) {
        this.currentTileBounds = null;
        return;
      }
      const northwestLat = this.tiles.map(t => t.bounds[0][0]);
      const northwestLng = this.tiles.map(t => t.bounds[0][1]);
      const southeastLat = this.tiles.map(t => t.bounds[1][0]);
      const southeastLng = this.tiles.map(t => t.bounds[1][1]);
      northwestLat.push(queryNorthwest.lat);
      northwestLng.push(queryNorthwest.lng);
      southeastLat.push(querySoutheast.lat);
      southeastLng.push(querySoutheast.lng);

      const northwest = L.latLng([
        Math.max(...northwestLat),
        Math.min(...northwestLng)
      ]);
      const southeast = L.latLng([
        Math.min(...southeastLat),
        Math.max(...southeastLng)
      ]);
      this.currentTileBounds = L.latLngBounds(northwest, southeast);
    }
  }
};
</script>
